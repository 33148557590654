import React, { useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import { Box, CircularProgress, Container } from "@mui/material";
import Banner from "../../components/banner/Banner";
import Products from "../../components/products/Products";
import Event18 from "../../components/event18/Event18";
import Infrastructure from "../../components/infrastructure/Infrastructure";
import Distribution from "../../components/distribution/Distribution";
import Testimonial from "../../components/testimonials/Testimonial";
import Footer from "../../components/footer/Footer";
import WorldMap from "../../components/worldMap/WorldMap";
import logo from "../../assets/videos/logo.mp4";

function Home() {
  return (
    <Box>
      {/* <div className="container"> */}
      {/* <Container maxWidth="xl"> */}
      {/* <div className="wrapper"> */}
      {/* <video width={"100%"} height={"100%"} style={{ borderRadius: "12px" }}>
          <source src={logo} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
      {/* <Box sx={{ display: "grid", placeItems: "center" }}>
          <CircularProgress />
        </Box> */}
      {/* </div> */}
      <Navbar />
      {/* </div> */}
      {/* </Container> */}
      <Banner />
      <Container maxWidth="xl">
        <Products />
      </Container>
      <Event18 />
      <Container maxWidth="xl">
        <Infrastructure />
      </Container>
      <Container maxWidth="xl">
        <WorldMap />
        {/* <Distribution /> */}
      </Container>
      <Container maxWidth="xl">
        <Testimonial />
      </Container>
      <Footer />
    </Box>
  );
}

export default Home;
