import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import img18 from "../../assets/images/181.png";
import "./event18.css";
import { useNavigate } from "react-router-dom";

function Event18() {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchesLg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <div className="event-18 my_80">
      <Container maxWidth="xl">
        <Box sx={{ width: "100%", py: 6 }}>
          <Grid
            container
            // spacing={5}
            columnSpacing={{ lg: 5, md: 0, xs: 0 }}
            // direction={!matchesMd ? "column" : "row"}
            justifyContent={!matchesMd ? "center" : "left"}
            alignItems="center"
            // sx={{ pb: 8 }}
          >
            <Grid
              item
              lg={4}
              md={5}
              xs={12}
              data-aos={matchesLg ? "fade-up" : "fade-up"}
            >
              <Box sx={{ width: !matchesMd ? "100%" : "60%" }}>
                <img
                  loading="lazy"
                  src={img18}
                  alt=""
                  width={"100%"}
                  height={"100%"}
                />
              </Box>
            </Grid>
            <Grid
              item
              lg={8}
              md={7}
              xs={12}
              data-aos={matchesLg ? "fade-up" : "fade-up"}
            >
              <Typography
                variant={matchesMd ? "h3" : "h4"}
                component="h3"
                color={"#fff"}
                mb={3}
              >
                Mature Minds Mixer: An Exclusive Evening of Elegance
              </Typography>
              <Typography
                variant="body"
                component="div"
                color={"hsla(0,0%,100%,.6)"}
                mb={3}
                sx={{ fontFamily: "'Inter', sans-serif!important" }}
              >
                This event was a testament to our commitment to fostering
                connections, promoting culture, and creating memorable
                experiences for our esteemed clientele. While the Mature Minds
                Mixer has concluded, stay tuned for future events that promise
                to be just as extraordinary.
              </Typography>
              <Typography
                variant="body"
                component="div"
                color={"hsla(0,0%,100%,.6)"}
                sx={{ fontFamily: "'Inter', sans-serif!important" }}
              >
                At our company, we believe in providing unique and enriching
                experiences, and the Mature Minds Mixer was a shining example of
                this commitment. Thank you to all who attended, and we look
                forward to welcoming you to our future events.
              </Typography>

              <Box mt={2}>
                <button
                  class="learn-more"
                  onClick={() => {
                    navigate("/18+");
                  }}
                >
                  <span class="circle" aria-hidden="true">
                    <span class="icon arrow"></span>
                  </span>
                  <span class="button-text">Find Out More</span>
                </button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default Event18;
