import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";

function SocialWorkCard({ imgUrl, title, description }) {
  return (
    <Box sx={{ mx: "auto", height: "inherit" }}>
      <Card
        sx={{
          // mL: 2,
          // mx: "auto",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          //   boxShadow: "none",
          height: "inherit",
        }}
      >
        <CardMedia sx={{ height: 280 }} image={imgUrl}>
          {/* <video
            controls
            width={"100%"}
            height={"100%"}
            // style={{ borderRadius: "12px" }}
          >
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}
        </CardMedia>
        <CardContent>
          <Typography gutterBottom variant="h6">
            {title ? title : ""}
          </Typography>
          {/* <Typography gutterBottom variant="body1" color="text.secondary">
            {position ? position : ""}
          </Typography> */}
          <Typography
            variant="body1"
            color={"rgb(96, 105, 123)"}
            sx={{ fontFamily: "'Inter', sans-serif!important" }}
          >
            {description ? description : ""}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

export default SocialWorkCard;
