import React from "react";
import product2 from "../../../assets/images/product2.png";

function Card({ data, title }) {
  console.log("data-----", data);
  return (
    <div className="card">
      <img
        loading="lazy"
        width={"100%"}
        height={"100%"}
        style={{ minHeight: "325px", maxHeight: "325px" }}
        src={data?.imgUrl}
        alt=""
      />
      <div className="card__content">
        <p className="card__title1" style={{ fontSize: "18px" }}>
          {title}
        </p>

        {/* <p className="card__title1 card_data">
          Name: <span className="card_value">{data?.type}</span>
        </p> */}
        {data?.title && (
          <p className="card__title1 card_data">
            Product Name : <span className="card_value">{data?.title}</span>
          </p>
        )}
        {data?.weight && (
          <p className="card__title1 card_data">
            Weight: <span className="card_value">{data?.weight}</span>
          </p>
        )}
        {data?.deniar && (
          <p className="card__title1 card_data">
            Denier : <span className="card_value">{data?.deniar}</span>
          </p>
        )}
        {/* <p className="card__title1 card_data">
          Evenness: <span className="card_value">{data?.evenness}</span>
        </p> */}
        {/* <p className="card__title1 card_data">
          Length : <span className="card_value">{data?.length}</span>
        </p> */}
        {data?.material && (
          <p className="card__title1 card_data">
            Material : <span className="card_value">{data?.material}</span>
          </p>
        )}
        {/* <p className="card__title1 card_data">
          Twist : <span className="card_value">{data?.twist}</span>
        </p>
        <p className="card__description">For {data?.usages}</p> */}
      </div>
    </div>
  );
}

export default Card;
