// import { Box, Container, Divider, Grid, Typography } from "@mui/material";
// import React from "react";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
// import Card from "./card/Card";
// import Heading from "../heading/Heading";

// function Products() {
//   const theme = useTheme();
//   const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
//   const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));

//   const products = [
//     {
//       type: "MType",
//       title: "FLAT FILM YARN(UNSUPPORTED)",
//       img: "",
//     },
//     {
//       type: "SType",
//       title: "FLAT FILM YARN(UNSUPPORTED)",
//       img: "",
//     },
//     {
//       type: "KType",
//       title: "FLAT FILM YARN(UNSUPPORTED)",
//       img: "",
//     },
//     {
//       type: "MSType",
//       title: "FLAT FILM YARN(UNSUPPORTED)",
//       img: "",
//     },
//   ];

//   return (
//     <Box sx={{ my: "80px" }}>
//       <Heading
//         title={"Our Products"}
//         subTitle="The main products of Aksharlon Jari company are embroidery all type
//         metallic colour yarn,Cording dori."
//       />

//       <Box
//         sx={{ marginTop: "30px", display: "flex", justifyContent: "center" }}
//         data-aos="fade-up"
//       >
//         <Grid
//           container
//           columnSpacing={5}
//           // direction={!matchesMd ? "column-reverse" : "row"}
//           justifyContent={{ sm: "flex-start", xs: "center" }}
//           alignItems="center"
//           sx={{ py: 3 }}
//         >
//           {products?.map((data) => {
//             return (
//               <Grid item lg={3} md={4} sm={6}>
//                 <Card data={data} />
//               </Grid>
//             );
//           })}
//         </Grid>
//       </Box>
//     </Box>
//   );
// }

// export default Products;

import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Card from "./card/Card";
import Heading from "../heading/Heading";
import naylonKasab from "../../assets/images/01-75. NAYLON KASAB.jpg";
import momoBadla from "../../assets/images/02-MX Singale momo Badla.jpg";
import doublingWithMomo from "../../assets/images/03-75-2 doubling with momo.jpg";
import doublingWithoutMomo from "../../assets/images/04 75-2 doubling without momo.jpg";
import chapatJari from "../../assets/images/07-chapat jari.jpg";
import banarsiDori from "../../assets/images/10 banarsi dori.jpg";
import mxBadala from "../../assets/images/11-MX Badala.jpg";
// import gilatarDori from "../../assets/images/13-gilatar dori.jpg";
import gilatarDori from "../../assets/images/13-gilatar dori.jpg";
import dyedYarn from "../../assets/images/18-dyed yarn.jpg";
import embroideryJari from "../../assets/images/20-embroidery jai.jpg";
import singalKasab from "../../assets/images/052A5614-75.singal kasab.jpg";
import raschelJari from "../../assets/images/052A5705 -raschel jari.jpg";
import polyester from "../../assets/images/150.96 Polyester -180.48 polyester.jpg";
import lacqueredCoatedFilm from "../../assets/images/lacquered coated film.jpg";
import metallic from "../../assets/images/1+ metalic.jpg";
import Slider from "react-slick";
import "./products.css";

function Products() {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    // autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const products = [
    {
      type: "naylon-kasab",
      title: "Naylon Kasab",
      img: naylonKasab,
    },
    {
      type: "02-mx-single-momo-badla",
      title: "02-MX Single momo Badla",
      img: momoBadla,
    },
    {
      type: "75-2-doubling-with-momo",
      title: "75/2 Doubling with Momo",
      img: doublingWithMomo,
    },
    {
      type: "75-2-doubling-without-momo",
      title: "75/2 Doubling without Momo",
      img: doublingWithoutMomo,
    },
    {
      type: "07-chapat-jari",
      title: "07 - Chapat Jari",
      img: chapatJari,
    },
    {
      type: "10-banarsi-dori",
      title: "10 Banarsi Dori",
      img: banarsiDori,
    },
    {
      type: "11-mx-badala",
      title: "11 MX Badala",
      img: mxBadala,
    },
    {
      type: "glitter-dori",
      title: "Glitter Dori",
      img: gilatarDori,
    },
    {
      type: "dayed-yarn",
      title: "Dayed Yarn",
      img: dyedYarn,
    },
    {
      type: "75-single-kasab",
      title: "75 Single Kasab",
      img: singalKasab,
    },
    {
      type: "embroidery-jari",
      title: "Embroidery Jari",
      img: embroideryJari,
    },
    {
      type: "raschel-jari",
      title: "Raschel Jari",
      img: raschelJari,
    },
    {
      type: "m150-96-polyester-180-48-polyester",
      title: "150/96 Polyester - 180/48 polyester",
      img: polyester,
    },
    {
      type: "lacquered-coated-film",
      title: "Lacquered Coated Film",
      img: lacqueredCoatedFilm,
    },
    {
      type: "metallic-yarn",
      title: "Metallic Yarn",
      img: metallic,
    },
  ];

  return (
    <Box sx={{ my: "80px" }}>
      <Heading
        title={"Our Products"}
        subTitle="The main products of Aksharlon Jari company are embroidery all type
        metallic colour yarn,Cording dori."
      />

      <Box
        sx={{ marginTop: "50px" }}
        data-aos="fade-up"
        className="products-slider"
      >
        <Slider {...settings}>
          {products?.map((data) => {
            return <Card data={data} />;
          })}
        </Slider>
        {/* <Grid
          container
          columnSpacing={5}
          // direction={!matchesMd ? "column-reverse" : "row"}
          justifyContent={{ sm: "flex-start", xs: "center" }}
          alignItems="center"
          sx={{ py: 3 }}
        >
          {products?.map((data) => {
            return (
              <Grid item lg={3} md={4} sm={6}>
                <Card data={data} />
              </Grid>
            );
          })}
        </Grid> */}
      </Box>
    </Box>
  );
}

export default Products;
