import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import about_company from "../../assets/images/about_company.png";
import Heading from "../heading/Heading";
import ownerImg from "../../assets/images/owner.png";

const BioGraphy = () => {
  return (
    <Box my={5}>
      {/* <Heading title={"ABOUT THE COMPANY"} subTitle="" /> */}
      <Box>
        <Grid
          container
          spacing={5}
          // direction={!matchesMd ? "column" : "row"}
          justifyContent="center"
          alignItems="center"
          sx={{ pb: 8 }}
        >
          <Grid item lg={4} data-aos="fade-right">
            <Box>
              <img
                loading="lazy"
                src={
                  "https://template72222.motopreview.com/mt-demo/72200/72222/mt-content/uploads/2018/11/mt-1642-about-img01.jpg"
                }
                alt=""
                width={"100%"}
              />
            </Box>
          </Grid>
          <Grid item lg={8} md={12} data-aos="fade-right">
            <Typography mb={3} variant="h5">
              About The Company
            </Typography>

            <Typography
              // variant={matchesMd ? "h3" : "h4"}
              component="body1"
              color={"#929292"}
              my={3}
              sx={{ fontFamily: "'Inter', sans-serif!important" }}
            >
              Aksharlon Jari was incorporated in 2006 inspired by unique
              advantages of providing high quality of Metallic Yarn with the
              unique name of "A-LON". We believe in delivering valuable and
              dependable solutions based on high end technology in the benefit
              of the textile industry.
            </Typography>
            <Typography
              variant="body1"
              component="div"
              color={"#929292"}
              my={3}
              sx={{ fontFamily: "'Inter', sans-serif!important" }}
            >
              Aksharlon Committed to deliver the the best quality products, we
              give paramount importance to it. We feel proud to say that we are
              producing the most different colours in the whole world.
            </Typography>
            <Typography
              variant="body1"
              component="div"
              color={"#929292"}
              sx={{ fontFamily: "'Inter', sans-serif!important" }}
            >
              The firm is using high quality of machinery in order to maintain
              the quality of product and because of providing qualitative
              product its selling is spread all over the world. The motto of
              firm is to provide "quality, range and economy".
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Grid
          container
          spacing={5}
          // direction={!matchesMd ? "column" : "row"}
          justifyContent="center"
          alignItems="center"
          sx={{ pb: 8 }}
        >
          <Grid item lg={8} md={12} data-aos="fade-left">
            <Typography mb={3} variant="h5">
              Vision
            </Typography>

            <Typography
              // variant={matchesMd ? "h3" : "h4"}
              component="body1"
              color={"#929292"}
              my={3}
              sx={{ fontFamily: "'Inter', sans-serif!important" }}
            >
              At AKSHAR LON, our focus is to develop jari that are of higher
              quality as well as affordable to our clients.
            </Typography>
            <Typography
              variant="body1"
              component="div"
              color={"#929292"}
              my={3}
              sx={{ fontFamily: "'Inter', sans-serif!important" }}
            >
              We are at the forefront of providing quality material that
              improves the quality of embroidery work done by machine which help
              client to have trust in our brands.
            </Typography>
            <Typography mb={3} variant="h5">
              Our Mission
            </Typography>

            <Typography
              // variant={matchesMd ? "h3" : "h4"}
              component="body1"
              color={"#929292"}
              my={3}
              sx={{ fontFamily: "'Inter', sans-serif!important" }}
            >
              We do our best to serve you with high-end business solutions that
              could help you stand out among competitors effortlessly.
            </Typography>
          </Grid>
          <Grid item lg={4} data-aos="fade-left">
            <Box>
              <img
                loading="lazy"
                src={
                  "https://template72222.motopreview.com/mt-demo/72200/72222/mt-content/uploads/2018/11/mt-1642-about-img02.jpg"
                }
                alt=""
                width={"100%"}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mb={8}>
        <img src={ownerImg} alt="" width={"100%"} height={"100%"} />
      </Box>
    </Box>
  );
};

export default BioGraphy;
