import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./banner.css";
import bannerImg from "../../assets/images/baner.png";
import infa1 from "../../assets/images/infa1.jpg";
import infa2 from "../../assets/images/infa2.jpg";
import infa3 from "../../assets/images/infa3.jpg";
// import infa4 from "../../assets/images/infa4.jpg";
import infa4 from "../../assets/images/akloanBanner.png";
import aklonMobile from "../../assets/images/aklonMobile.png";
import { collection, getDocs } from "firebase/firestore";
import { fireStore } from "../../firebase";
import { Box, CircularProgress, useMediaQuery, useTheme } from "@mui/material";

function Banner() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    arrows: false,
  };
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  const [banners, setBanners] = useState([]);
  const [mobileBanners, setMobileBanners] = useState([]);

  async function getBanners() {
    const querySnapshot = await getDocs(collection(fireStore, "banner"));
    //   console.log(querySnapshot);
    let banner = [];
    let mobileBanner = [];

    if (querySnapshot) {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data?.mobileView === true) {
          mobileBanner.push({ ...data, id: doc.id });
        } else {
          banner.push({ ...data, id: doc.id });
        }
      });
    }
    setMobileBanners([...mobileBanner]);
    setBanners([...banner]);
  }

  useEffect(() => {
    getBanners();
  }, []);

  return (
    <>
      {banners.length > 0 ? (
        <div className="banner-section">
          <Slider {...settings}>
            {(banners?.length <= 0 || mobileBanners?.length <= 0) && (
              <div className="banner-image-wrapper">
                {/* <div className="wrapper"></div> */}
                <img
                  loading="lazy"
                  src={matchesMd ? infa4 : aklonMobile}
                  alt=""
                  className="banner-image"
                />
              </div>
            )}
            {matchesMd
              ? banners?.map((data) => {
                  return (
                    <div className="banner-image-wrapper">
                      {/* <div className="wrapper"></div> */}
                      <img
                        loading="lazy"
                        src={data?.imgUrl}
                        alt=""
                        className="banner-image"
                      />
                    </div>
                  );
                })
              : mobileBanners?.map((data) => {
                  return (
                    <div className="banner-image-wrapper">
                      {/* <div className="wrapper"></div> */}
                      <img
                        loading="lazy"
                        src={data?.imgUrl}
                        alt=""
                        className="banner-image"
                      />
                    </div>
                  );
                })}
            {/* <div className="banner-image-wrapper">
          <div className="wrapper"></div>
          <img loading="lazy"
            src={"https://www.adlonindustries.com/img/Banner/6.webp"}
            alt=""
            className="banner-image"
          />
        </div>
        <div className="banner-image-wrapper">
          <div className="wrapper"></div>
          <img loading="lazy" src={infa3} alt="" className="banner-image" />
        </div>
        <div className="banner-image-wrapper">
          <div className="wrapper"></div>
          <img loading="lazy"
            src={"https://www.adlonindustries.com/img/Banner/7.webp"}
            alt=""
            className="banner-image"
          />
        </div> */}
          </Slider>
        </div>
      ) : (
        <Box sx={{ display: "grid", height: "80vh", placeItems: "center" }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

export default Banner;
