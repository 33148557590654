import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import SmallBanner from "../../components/smallBanner/SmallBanner";
import journey2 from "../../assets/images/journey2.png";
import contactImg from "../../assets/images/contact5.png";
import { Avatar, Box, Container, Grid, Typography } from "@mui/material";
import Contactus from "../../components/contactus/Contactus";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";

function Contact() {
  const navSteps = {
    first: { name: "Home", url: "/" },
    second: { name: "contact", url: "" },
  };
  return (
    <Box>
      {/* <div className="container"> */}
      <Navbar viewNav={true} />
      {/* </div> */}
      <SmallBanner
        title="Contact Us"
        img={contactImg}
        navigationSteps={navSteps}
      />
      <Container maxWidth="xl">
        <Box
          sx={{ marginTop: "30px", display: "flex", justifyContent: "center" }}
          data-aos="fade-up"
        >
          <Grid
            container
            spacing={5}
            // justifyContent="center"
            alignItems="center"
            sx={{ py: 3 }}
          >
            <Grid item lg={3} md={6}>
              <Box
                textAlign={"center"}
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                boxShadow={"rgba(0, 0, 0, 0.15) 0px 2px 8px"}
                p={3}
                borderRadius={"8px"}
              >
                <Avatar
                  sx={{ bgcolor: "#192257", width: 56, height: 56, mx: "auto" }}
                  variant="rounded"
                >
                  <LocationOnOutlinedIcon fontSize="large" />
                </Avatar>
                <Box mt={2} mx={"auto"} textAlign={"center"}>
                  <Typography variant="h6" component="h6" mb={2}>
                    {" "}
                    Main Factory{" "}
                  </Typography>
                  <Typography variant="subtitle2" component="h6">
                    <a
                      href=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "inherit",
                      }}
                    >
                      {/* <CallOutlinedIcon fontSize="small" /> */}
                      <span> +91 98258 26240</span>
                    </a>
                    <a
                      href=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "inherit",
                      }}
                    >
                      {/* <CallOutlinedIcon fontSize="small" /> */}
                      <span> +91 93776 98875</span>
                    </a>
                    <a
                      href=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "inherit",
                      }}
                    >
                      {/* <CallOutlinedIcon fontSize="small" /> */}
                      <span> +91 83205 37914</span>
                    </a>
                    <a
                      href=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "inherit",
                      }}
                    >
                      {/* <CallOutlinedIcon fontSize="small" /> */}
                      <span> aksharlonjari@gmail.com</span>
                    </a>
                    <a
                      href=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        margin: "8px 0 0 0",
                        color: "inherit",
                        // justifyContent: !matchesMd ? "center" : "flex-start",
                        // alignItems: "center",
                      }}
                    >
                      {/* <LocationOnOutlinedIcon fontSize="small" /> */}
                      <span>
                        F-2,5 to 8, Bhagwati Nagar Ind., Nr.Navin Fluorine Co.,
                        Bhestan, Surat - 395023 Gujarat India.
                      </span>
                    </a>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3} md={6}>
              <Box
                textAlign={"center"}
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                boxShadow={"rgba(0, 0, 0, 0.15) 0px 2px 8px"}
                p={3}
                borderRadius={"8px"}
              >
                <Avatar
                  sx={{ bgcolor: "#192257", width: 56, height: 56, mx: "auto" }}
                  variant="rounded"
                >
                  <LocationOnOutlinedIcon fontSize="large" />
                </Avatar>
                <Box mt={2} mx={"auto"} textAlign={"center"}>
                  <Typography variant="h6" component="h6" mb={2}>
                    {" "}
                    Corporate Office{" "}
                  </Typography>
                  <Typography variant="subtitle2" component="h6">
                    <a
                      href=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "inherit",
                      }}
                    >
                      {/* <CallOutlinedIcon fontSize="small" /> */}
                      <span> +91 98258 26240</span>
                    </a>
                    <a
                      href=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "inherit",
                      }}
                    >
                      {/* <CallOutlinedIcon fontSize="small" /> */}
                      <span> +91 93776 98875</span>
                    </a>
                    <a
                      href=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "inherit",
                      }}
                    >
                      {/* <CallOutlinedIcon fontSize="small" /> */}
                      <span> +91 83205 37914</span>
                    </a>
                    <a
                      href=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "inherit",
                      }}
                    >
                      {/* <CallOutlinedIcon fontSize="small" /> */}
                      <span> aksharlonjari@gmail.com</span>
                    </a>
                    <a
                      href=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        margin: "8px 0 0 0",
                        color: "inherit",
                        // justifyContent: !matchesMd ? "center" : "flex-start",
                        // alignItems: "center",
                      }}
                    >
                      {/* <LocationOnOutlinedIcon fontSize="small" /> */}
                      <span>
                        F-2,5 to 8, Bhagwati Nagar Ind., Nr.Navin Fluorine Co.,
                        Bhestan, Surat - 395023 Gujarat India.
                      </span>
                    </a>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3} md={6}>
              <Box
                textAlign={"center"}
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                boxShadow={"rgba(0, 0, 0, 0.15) 0px 2px 8px"}
                p={3}
                borderRadius={"8px"}
              >
                <Avatar
                  sx={{ bgcolor: "#192257", width: 56, height: 56, mx: "auto" }}
                  variant="rounded"
                >
                  <LocationOnOutlinedIcon fontSize="large" />
                </Avatar>
                <Box mt={2} mx={"auto"} textAlign={"center"}>
                  <Typography variant="h6" component="h6" mb={2}>
                    {" "}
                    Pipodra Factory{" "}
                  </Typography>
                  <Typography variant="subtitle2" component="h6">
                    <a
                      href=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "inherit",
                      }}
                    >
                      {/* <CallOutlinedIcon fontSize="small" /> */}
                      <span> +91 98258 26240</span>
                    </a>
                    <a
                      href=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "inherit",
                      }}
                    >
                      {/* <CallOutlinedIcon fontSize="small" /> */}
                      <span> +91 93776 98875</span>
                    </a>
                    <a
                      href=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "inherit",
                      }}
                    >
                      {/* <CallOutlinedIcon fontSize="small" /> */}
                      <span> +91 83205 37914</span>
                    </a>
                    <a
                      href=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "inherit",
                      }}
                    >
                      {/* <CallOutlinedIcon fontSize="small" /> */}
                      <span> aksharlonjari@gmail.com</span>
                    </a>
                    <a
                      href=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        margin: "8px 0 0 0",
                        color: "inherit",
                        // justifyContent: !matchesMd ? "center" : "flex-start",
                        // alignItems: "center",
                      }}
                    >
                      {/* <LocationOnOutlinedIcon fontSize="small" /> */}
                      <span>
                        F-2,5 to 8, Bhagwati Nagar Ind., Nr.Navin Fluorine Co.,
                        Bhestan, Surat - 395023 Gujarat India.
                      </span>
                    </a>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={3} md={6}>
              <Box
                textAlign={"center"}
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                boxShadow={"rgba(0, 0, 0, 0.15) 0px 2px 8px"}
                p={3}
                borderRadius={"8px"}
              >
                <Avatar
                  sx={{ bgcolor: "#192257", width: 56, height: 56, mx: "auto" }}
                  variant="rounded"
                >
                  <LocationOnOutlinedIcon fontSize="large" />
                </Avatar>
                <Box mt={2} mx={"auto"} textAlign={"center"}>
                  <Typography variant="h6" component="h6" mb={2}>
                    {" "}
                    Navsari Factory{" "}
                  </Typography>
                  <Typography variant="subtitle2" component="h6">
                    <a
                      href=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "inherit",
                      }}
                    >
                      {/* <CallOutlinedIcon fontSize="small" /> */}
                      <span> +91 98258 26240</span>
                    </a>
                    <a
                      href=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "inherit",
                      }}
                    >
                      {/* <CallOutlinedIcon fontSize="small" /> */}
                      <span> +91 93776 98875</span>
                    </a>
                    <a
                      href=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "inherit",
                      }}
                    >
                      {/* <CallOutlinedIcon fontSize="small" /> */}
                      <span> +91 83205 37914</span>
                    </a>
                    <a
                      href=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "inherit",
                      }}
                    >
                      {/* <CallOutlinedIcon fontSize="small" /> */}
                      <span> aksharlonjari@gmail.com</span>
                    </a>
                    <a
                      href=""
                      style={{
                        display: "flex",
                        gap: "8px",
                        margin: "8px 0 0 0",
                        color: "inherit",
                        // justifyContent: !matchesMd ? "center" : "flex-start",
                        // alignItems: "center",
                      }}
                    >
                      {/* <LocationOnOutlinedIcon fontSize="small" /> */}
                      <span>
                        F-2,5 to 8, Bhagwati Nagar Ind., Nr.Navin Fluorine Co.,
                        Bhestan, Surat - 395023 Gujarat India.
                      </span>
                    </a>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container maxWidth="xl">
        <Contactus />
      </Container>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7443.02888022511!2d72.857405!3d21.131916000000004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be051e769a2d097%3A0x3489d1e1965fd43c!2sAkshar%20Lon!5e0!3m2!1sen!2sin!4v1694155923224!5m2!1sen!2sin"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      <Footer />
    </Box>
  );
}
export default Contact;
