import { Box, Typography } from "@mui/material";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

function Heading({ title, subTitle }) {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography
        variant={"h4"}
        component="h2"
        color={"#192257"}
        sx={{ fontWeight: "bold" }}
        className="heading-h2"
      >
        {title ? title : ""}
      </Typography>
      <Typography
        variant={"body1"}
        color={"rgb(96, 105, 123)"}
        my={1}
        sx={{
          width: matchesMd ? "45%" : "100%",
          mx: "auto",
          fontFamily: "'Inter', sans-serif!important",
        }}
      >
        {subTitle ? subTitle : ""}
      </Typography>
    </Box>
  );
}

export default Heading;
