import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function CardTestimonial({ videoUrl, author, position, description, image }) {
  return (
    <Box sx={{ mx: "auto" }}>
      <Card
        sx={{
          mL: 2,
          width: "90%",
          mx: "auto",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        }}
      >
        <CardMedia sx={{ height: "auto" }}>
          {image && (
            <img
              loading="lazy"
              src={videoUrl}
              alt=""
              width={"100%"}
              height={"100%"}
            />
          )}
          {!image && (
            <video
              controls
              width={"100%"}
              height={"100%"}
              // style={{ borderRadius: "12px" }}
            >
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </CardMedia>
        <CardContent>
          <Typography gutterBottom variant="h6">
            {author ? author : ""}
          </Typography>
          <Typography gutterBottom variant="body1" color="text.secondary">
            {position ? position : ""}
          </Typography>
          <Typography
            variant="body2"
            color={"rgb(96, 105, 123)"}
            sx={{ fontFamily: "'Inter', sans-serif!important" }}
          >
            {description ? description : ""}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

export default CardTestimonial;
