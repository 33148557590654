import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { Box, Typography } from "@mui/material";
import Heading from "../heading/Heading";
import fest1 from "../../assets/images/fest1.jpg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function TimeLine() {
  return (
    <Box sx={{ my: "80px" }}>
      <Heading
        title={"Our Product Journey"}
        subTitle="Explore the evolution of our products, from inception to perfection."
      />

      <Box
        sx={{ mt: 5, display: "flex", justifyContent: "center" }}
        // data-aos="fade-up"
      >
        <Timeline position="alternate">
          <TimelineItem data-aos="fade-up">
            <TimelineOppositeContent color="text.secondary">
              March, 2017
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ pb: 6, px: 2 }}>
              {" "}
              <div className={``}>
                <Typography gutterBottom variant="h5">
                  Festivals of 2023
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  //   sx={{ color: "#d2d2d2" }}
                >
                  At Aksharlon, we believe that festivals are not just
                  gatherings but opportunities to build connections and
                  strengthen communities. Our festivals are inclusive spaces
                  where people of all backgrounds come together to share in the
                  joys of life, fostering bonds that go beyond the event itself.
                </Typography>
              </div>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem data-aos="fade-up">
            <TimelineOppositeContent color="text.secondary">
              January, 2018
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ pb: 6, px: 2 }}>
              {" "}
              <div className={``}>
                <Typography gutterBottom variant="h5">
                  Festivals of 2023
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  //   sx={{ color: "#d2d2d2" }}
                >
                  At Aksharlon, we believe that festivals are not just
                  gatherings but opportunities to build connections and
                  strengthen communities. Our festivals are inclusive spaces
                  where people of all backgrounds come together to share in the
                  joys of life, fostering bonds that go beyond the event itself.
                </Typography>
              </div>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem data-aos="fade-up">
            <TimelineOppositeContent color="text.secondary">
              February, 2019
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ pb: 6, px: 2 }}>
              {" "}
              <div className={``}>
                <Typography gutterBottom variant="h5">
                  Festivals of 2023
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  //   sx={{ color: "#d2d2d2" }}
                >
                  At Aksharlon, we believe that festivals are not just
                  gatherings but opportunities to build connections and
                  strengthen communities. Our festivals are inclusive spaces
                  where people of all backgrounds come together to share in the
                  joys of life, fostering bonds that go beyond the event itself.
                </Typography>

                <div className={``}>
                  <div className="festCardImg">
                    <img
                      loading="lazy"
                      src={fest1}
                      alt=""
                      srcset=""
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px 10px 0 0",
                      }}
                    />
                  </div>
                </div>
              </div>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem data-aos="fade-up">
            <TimelineOppositeContent color="text.secondary">
              April, 2020
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ pb: 6, px: 2 }}>
              {" "}
              <div className={``}>
                <Typography gutterBottom variant="h5">
                  Festivals of 2023
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  //   sx={{ color: "#d2d2d2" }}
                >
                  At Aksharlon, we believe that festivals are not just
                  gatherings but opportunities to build connections and
                  strengthen communities. Our festivals are inclusive spaces
                  where people of all backgrounds come together to share in the
                  joys of life, fostering bonds that go beyond the event itself.
                </Typography>
                <div className={``}>
                  <div className="festCardImg">
                    <img
                      loading="lazy"
                      src={fest1}
                      alt=""
                      srcset=""
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px 10px 0 0",
                      }}
                    />
                  </div>
                </div>
              </div>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem data-aos="fade-up">
            <TimelineOppositeContent color="text.secondary">
              June, 2021
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ pb: 6, px: 2 }}>
              {" "}
              <div className={``}>
                <Typography gutterBottom variant="h5">
                  Festivals of 2023
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  //   sx={{ color: "#d2d2d2" }}
                >
                  At Aksharlon, we believe that festivals are not just
                  gatherings but opportunities to build connections and
                  strengthen communities. Our festivals are inclusive spaces
                  where people of all backgrounds come together to share in the
                  joys of life, fostering bonds that go beyond the event itself.
                </Typography>
              </div>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem data-aos="fade-up">
            <TimelineOppositeContent color="text.secondary">
              September, 2022
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ pb: 6, px: 2 }}>
              {" "}
              <div className={``}>
                <Typography gutterBottom variant="h5">
                  Festivals of 2023
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  //   sx={{ color: "#d2d2d2" }}
                >
                  At Aksharlon, we believe that festivals are not just
                  gatherings but opportunities to build connections and
                  strengthen communities. Our festivals are inclusive spaces
                  where people of all backgrounds come together to share in the
                  joys of life, fostering bonds that go beyond the event itself.
                </Typography>
              </div>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem data-aos="fade-up">
            <TimelineOppositeContent color="text.secondary">
              August, 2023
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ pb: 6, px: 2 }}>
              {" "}
              <div className={``}>
                <Typography gutterBottom variant="h5">
                  Festivals of 2023
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  //   sx={{ color: "#d2d2d2" }}
                >
                  At Aksharlon, we believe that festivals are not just
                  gatherings but opportunities to build connections and
                  strengthen communities. Our festivals are inclusive spaces
                  where people of all backgrounds come together to share in the
                  joys of life, fostering bonds that go beyond the event itself.
                </Typography>
                <div className={``}>
                  <div className="festCardImg">
                    <img
                      loading="lazy"
                      src={fest1}
                      alt=""
                      srcset=""
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px 10px 0 0",
                      }}
                    />
                  </div>
                </div>
              </div>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem data-aos="fade-up">
            {/* <TimelineSeparator> */}
            <Typography sx={{ mt: 2, fontWeight: "bold" }}>
              Still, our journey is start
            </Typography>
            {/* <TimelineDot /> */}
            {/* </TimelineSeparator> */}
            <TimelineContent></TimelineContent>
          </TimelineItem>
        </Timeline>
      </Box>
    </Box>
  );
}

export default TimeLine;
