import { Box, Container } from "@mui/material";
import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import event from "../../assets/images/event.png";
import SmallBanner from "../../components/smallBanner/SmallBanner";
import TimeLine from "../../components/timeLine/TimeLine";
import journey from "../../assets/images/journey.jpg";
import journey1 from "../../assets/images/journey1.png";
import journey2 from "../../assets/images/journey2.png";

function EighteenPlus() {
  const navSteps = {
    first: { name: "Home", url: "/" },
    second: { name: "18+", url: "" },
  };
  return (
    <Box>
      {/* <div className="container"> */}
      <Navbar viewNav={true} />
      {/* </div> */}
      <SmallBanner
        title="18+ Event"
        img={journey2}
        navigationSteps={navSteps}
      />
      <Container maxWidth="xl">
        <TimeLine />
      </Container>
      <Footer />
    </Box>
  );
}

export default EighteenPlus;
