import "./App.css";
import Home from "./pages/home";
import { Routes, Route, Navigate } from "react-router-dom";
import Products from "./pages/products";
import Gallery from "./pages/gallery";
import Craft from "./pages/craft";
import About from "./pages/about";
import Contact from "./pages/contact";
import EighteenPlus from "./pages/18+";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:category" element={<Products />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/the-craft" element={<Craft />} />
        <Route path="/18+" element={<EighteenPlus />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </>
  );
}

export default App;
