import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import videoInfa from "../../assets/videos/infastrucutre.webm";
import Heading from "../heading/Heading";
import CardTestimonial from "./card/CardTestimonial";
import "./testimonial.css";
import social1 from "../../assets/images/avtar12.png";
import social2 from "../../assets/images/avtar11.png";
import { collection, getDocs } from "firebase/firestore";
import { fireStore } from "../../firebase";

function Testimonial() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    // autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [events, setEvents] = React.useState([]);

  async function getTestimonials() {
    const querySnapshot = await getDocs(collection(fireStore, "testimonial"));
    //   console.log(querySnapshot);
    let event = [];

    if (querySnapshot) {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        event.push({ ...data, id: doc.id });
      });
    }
    setEvents([...event]);
  }

  useEffect(() => {
    getTestimonials();
  }, []);

  return (
    <Box sx={{ my: 14 }}>
      <Heading
        title={"Our Testimonials"}
        subTitle={
          "Explore client testimonials showcasing real satisfaction and success stories, a testament to our commitment to excellence."
        }
      />

      <Box
        sx={{ my: 8 }}
        className="testimonial-slider"
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
      >
        {events?.length > 0 ? (
          <Slider {...settings}>
            {events?.map((data) => {
              return (
                <CardTestimonial
                  author="Coriss Ambady"
                  position="Financial Analyst"
                  description={
                    "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica"
                  }
                  videoUrl={social1}
                  image={true}
                />
              );
            })}

            {/* <CardTestimonial
            author="Coriss Ambady"
            position="Financial Analyst"
            description={
              "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica"
            }
            videoUrl={social1}
            image={true}
          />
          <CardTestimonial
            author="Coriss Ambady"
            position="Financial Analyst"
            description={
              "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica"
            }
            videoUrl={social2}
            image={true}
          />
          <CardTestimonial
            author="Coriss Ambady"
            position="Financial Analyst"
            description={
              "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica"
            }
            videoUrl={videoInfa}
          />
          <CardTestimonial
            author="Coriss Ambady"
            position="Financial Analyst"
            description={
              "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica"
            }
            videoUrl={videoInfa}
          />
          <CardTestimonial
            author="Coriss Ambady"
            position="Financial Analyst"
            description={
              "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica"
            }
            videoUrl={videoInfa}
          /> */}
          </Slider>
        ) : (
          <Box sx={{ display: "grid", placeItems: "center", mt: 5 }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Testimonial;
