import React, { useEffect } from "react";
import Heading from "../heading/Heading";
import { Box, CircularProgress, Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import social1 from "../../assets/images/social1.png";
import social2 from "../../assets/images/social2.png";
import social3 from "../../assets/images/social3.png";
import SocialWorkCard from "./card/SocialWorkCard";
import { collection, getDocs } from "firebase/firestore";
import { fireStore, fireStorage } from "../../firebase";

function SocialWorks() {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  const [socialWorksData, setSocialWorksData] = React.useState([]);

  async function getSocialWork() {
    const querySnapshot = await getDocs(collection(fireStore, "socialWork"));
    //   console.log(querySnapshot);
    let event = [];

    if (querySnapshot) {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        event.push({ ...data, id: doc.id });
      });
    }
    setSocialWorksData([...event]);
  }

  useEffect(() => {
    getSocialWork();
  }, []);

  return (
    <Box sx={{ my: "80px" }}>
      <Heading title={"Our Social Works"} subTitle="" />

      {socialWorksData?.length > 0 ? (
        <Box
          sx={{ marginTop: "30px", display: "flex", justifyContent: "center" }}
          data-aos="fade-up"
        >
          <Grid
            container
            spacing={5}
            direction={!matchesMd ? "column" : "row"}
            // justifyContent="center"
            //   alignItems="center"
            sx={{ py: 3 }}
          >
            {socialWorksData.map((data) => {
              return (
                <Grid item lg={4}>
                  <SocialWorkCard
                    imgUrl={data?.fileUrl}
                    title={data?.title}
                    description={data?.description}
                  />
                </Grid>
              );
            })}

            {/* <Grid item lg={4}>
            <SocialWorkCard
              imgUrl={social2}
              title={"Women's Empowerment and Gender Equality"}
              description={
                "Gender equality is fundamental to a just and equitable society. Our women's empowerment program works towards creating opportunities for women to thrive. We offer vocational training."
              }
            />
          </Grid>
          <Grid item lg={4}>
            <SocialWorkCard
              imgUrl={social3}
              title={"Environmental Conservation and Sustainability"}
              description={
                "Our planet faces unprecedented environmental challenges, from climate change to biodiversity loss. Our environmental conservation and sustainability project tackles these issues head-on."
              }
            />
          </Grid>
          <Grid item lg={4}>
            <SocialWorkCard
              imgUrl={social2}
              title={"Clean Water for All"}
              description="Access to clean and safe drinking water is a basic human right, yet millions of people lack this essential resource. Our 'Clean Water for All' program aims to address this pressing issue by building."
            />
          </Grid>
          <Grid item lg={4}>
            <SocialWorkCard
              imgUrl={social3}
              title={"Youth Mentorship and Development"}
              description="Investing in the youth is an investment in the future. Our mentorship and development program pairs experienced mentors with young individuals, providing guidance, support, and skill-building opportunities."
            />
          </Grid>
          <Grid item lg={4}>
            <SocialWorkCard
              imgUrl={social1}
              title={"Community Empowerment Through Education"}
              description={
                "This project focuses on providing underprivileged communities with access to quality education. We believe that education is the key to breaking the cycle of poverty and empowering individuals."
              }
            />
          </Grid> */}
          </Grid>
        </Box>
      ) : (
        <Box sx={{ display: "grid", placeItems: "center", mt: 5 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export default SocialWorks;
