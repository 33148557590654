import React from "react";
import "./card.css";
import productImg from "../../../assets/images/product1.png";
import { Link, useNavigate } from "react-router-dom";

function Card({ data }) {
  const navigate = useNavigate();
  return (
    <div
      className="product-card"
      onClick={() => {
        navigate(`/products/${data?.type?.toLowerCase()}`);
      }}
    >
      <div className="product-img-div">
        <div className="product-img-wrapper">
          <img
            loading="lazy"
            src={data?.img ? data?.img : productImg}
            alt=""
            className="productImg"
          />
        </div>
        <div
          // to={`/products/${data?.type?.toLowerCase()}`}
          className="read-more-product"
        >
          <p className="">READ MORE</p>
        </div>
      </div>
      <div className="product-info">
        <h4>{data?.type}</h4>
        <p className="description">{data?.title}</p>
      </div>
    </div>
  );
}

export default Card;
