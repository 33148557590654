import { Box, CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Heading from "../heading/Heading";
import certificate from "../../assets/images/certificate.png";
import { collection, getDocs } from "firebase/firestore";
import { fireStore } from "../../firebase";

const Certificate = () => {
  const [certificates, setCertificates] = useState([]);

  async function getCertificates() {
    const querySnapshot = await getDocs(collection(fireStore, "certificate"));
    //   console.log(querySnapshot);
    let certificate = [];

    if (querySnapshot) {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        certificate.push({ ...data, id: doc.id });
      });
    }
    setCertificates([...certificate]);
  }

  useEffect(() => {
    getCertificates();
  }, []);

  return (
    <Box>
      <Heading title={"Our Certificate"} subTitle="" />
      {certificates?.length > 0 ? (
        <Box my={5}>
          <Grid
            container
            spacing={{ xs: 2, md: 3, lg: 5 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {certificates.map((data) => {
              return (
                <Grid item xs={2} sm={4} md={4} data-aos="fade-left">
                  <img
                    loading="lazy"
                    src={data?.imgUrl}
                    alt=""
                    width={"100%"}
                  />
                </Grid>
              );
            })}

            {/* <Grid item xs={2} sm={4} md={4} data-aos="fade-up">
            <img loading="lazy" src={certificate} alt="" width={"100%"} />
          </Grid>
          <Grid item xs={2} sm={4} md={4} data-aos="fade-right">
            <img loading="lazy" src={certificate} alt="" width={"100%"} />
          </Grid> */}
          </Grid>
        </Box>
      ) : (
        <Box sx={{ display: "grid", placeItems: "center", mt: 5 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default Certificate;
