import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./navbar.css";
import { Box, Container, Grid } from "@mui/material";
import logo from "../../assets/images/ALON-Logo.png";
import footerLogo from "../../assets/images/AksharlonIndustriesWhite.png";
import { Link, useLocation } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CategoryIcon from "@mui/icons-material/Category";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import AddchartIcon from "@mui/icons-material/Addchart";
import CollectionsIcon from "@mui/icons-material/Collections";
import TimelineIcon from "@mui/icons-material/Timeline";
import ContactPhoneRoundedIcon from "@mui/icons-material/ContactPhoneRounded";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";

function Navbar({ viewNav }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [sidebar, setSidebar] = React.useState(false);

  const [colorChange, setColorchange] = useState(false);
  // const changeNavbarColor = () => {
  //   if (window.scrollY >= 80) {
  //     setColorchange(true);
  //   } else {
  //     setColorchange(false);
  //   }
  // };
  // window.addEventListener("scroll", changeNavbarColor);

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : "100%" }}
      role="presentation"
      onClick={() => {
        setSidebar(false);
      }}
      onKeyDown={() => {
        setSidebar(false);
      }}
    >
      <Box sx={{ p: 3, bgcolor: "#192257" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <a href="/">
              <img
                loading="lazy"
                src={footerLogo}
                alt=""
                height={60}
                width={90}
              />
            </a>
          </Grid>

          <Grid item xs={6} sx={{ textAlign: "end" }}>
            <DisabledByDefaultRoundedIcon
              sx={{
                height: "30px",
                width: "30px",
                color: "#ffffff",
                cursor: "pointer",
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <List sx={{ p: 1 }}>
        {[
          { url: "/", name: "Home", icon: <HomeIcon /> },
          { url: "/about-us", name: "About Us", icon: <InfoIcon /> },
          { url: "/products", name: "Products", icon: <CategoryIcon /> },
          // {
          //   url: "/",
          //   name: "Distribution",
          //   icon: <TravelExploreIcon />,
          // },
          // { url: "/", name: "Infrastructure", icon: <AddchartIcon /> },
          { url: "/gallery", name: "Gallery", icon: <CollectionsIcon /> },
          { url: "/the-craft", name: "The Craft", icon: <TimelineIcon /> },
          { url: "/18+", name: "18+", icon: <EmojiEventsOutlinedIcon /> },
          {
            url: "/contact",
            name: "Contact Us",
            icon: <ContactPhoneRoundedIcon />,
          },
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              onClick={() => {
                navigate(text.url);
              }}
            >
              <ListItemIcon sx={{ color: "#192257" }}>
                {text?.icon}
              </ListItemIcon>
              <ListItemText sx={{ color: "#192257" }} primary={text?.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const handlePages = () => {
    const page = location.pathname;

    if (page !== "/") {
      navigate("/");
    }
  };

  // console.log("location.pathname", location.pathname);

  return (
    <div className={`header ${colorChange || viewNav ? "" : ""}`} id="header">
      <Container maxWidth="xl">
        <div className="navbar-header">
          <Grid
            container
            // spacing={10}
            // direction={!matchesMd ? "column-reverse" : "row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xl={6} lg={3} md={3} sm={6}>
              <Link to="/">
                <img
                  loading="lazy"
                  src={colorChange || viewNav ? logo : logo}
                  alt="Aksharlon Industries"
                  className="img-center"
                />
              </Link>
            </Grid>
            <Grid item xl={6} lg={9} md={9} sm={6}>
              <ul className="navbar">
                {/* <li className="nav-item">
              <a href="">
                <img loading="lazy"
                  src="https://aksharlon.com/images/logo.png"
                  alt=""
                  width={70}
                  height={50}
                />
              </a>
            </li> */}
                <li
                  className={`nav-item ${
                    location.pathname === "/" ? "activeNav" : ""
                  }`}
                >
                  <Link to="/">Home</Link>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/about-us" ? "activeNav" : ""
                  }`}
                >
                  <Link to="/about-us">About Us</Link>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/products" ? "activeNav" : ""
                  }`}
                >
                  <Link to="/products">Products</Link>
                </li>
                {/* <li
                  className={`nav-item ${
                    location.pathname === "/#infrastructure" ? "activeNav" : ""
                  }`}
                >
                  <a href="#infrastructure" onClick={handlePages}>
                    Infrastructure
                  </a>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/#distribution" ? "activeNav" : ""
                  }`}
                >
                  <a href="#distribution" onClick={handlePages}>
                    Distribution
                  </a>
                </li> */}
                <li
                  className={`nav-item ${
                    location.pathname === "/gallery" ? "activeNav" : ""
                  }`}
                >
                  <Link to="/gallery">Gallery</Link>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/the-craft" ? "activeNav" : ""
                  }`}
                >
                  <Link to="/the-craft">The Craft</Link>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/18+" ? "activeNav" : ""
                  }`}
                >
                  <Link to="/18+">18+</Link>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/contact" ? "activeNav" : ""
                  }`}
                >
                  <Link to="/contact">Contact Us</Link>
                </li>
                <li className="nav-item menuNavItem">
                  <Button
                    onClick={() => {
                      setSidebar(!sidebar);
                    }}
                  >
                    <MenuIcon
                      sx={{
                        color: colorChange || viewNav ? "#192257" : "#192257",
                      }}
                    />
                  </Button>
                </li>
              </ul>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Drawer
        anchor={"left"}
        open={sidebar}
        onClose={() => {
          setSidebar(false);
        }}
        disableScrollLock={true}
      >
        {list("left")}
      </Drawer>
    </div>
  );
}

export default Navbar;
