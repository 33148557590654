import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import Link from "@mui/material/Link";
import productCatlog from "../../assets/images/productCatlog2.png";

function SmallBanner({ title, navigationSteps, img }) {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <Box sx={{ background: "#192257", marginTop: "85px" }}>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={10}
          // direction={!matchesMd ? "column-reverse" : "row"}
          justifyContent="center"
          alignItems="center"
          sx={{ pb: 3 }}
        >
          <Grid
            item
            md={6}
            data-aos="fade-right"
            sx={{ pt: "10px !important" }}
          >
            <Typography variant="h4" component="h4" color={"#fff"} mb={3}>
              {title}
            </Typography>
            <Breadcrumbs
              aria-label="breadcrumb"
              color={"#d2d2d2"}
              sx={{ fontSize: "15px" }}
            >
              <Link
                underline="hover"
                color="inherit"
                href={navigationSteps?.first?.url}
              >
                {navigationSteps?.first?.name}
              </Link>
              <Typography sx={{ color: "#fff", fontSize: "15px" }}>
                {navigationSteps?.second?.name}
              </Typography>
            </Breadcrumbs>
          </Grid>

          <Grid
            item
            md={4}
            data-aos="fade-right"
            sx={{ pt: "10px !important" }}
          >
            <div
              style={{ width: matchesMd ? "500px" : "100%", height: "260px" }}
            >
              {img && (
                <img
                  loading="lazy"
                  src={img}
                  alt=""
                  width={"100%"}
                  height={"100%"}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default SmallBanner;
