import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Heading from "../heading/Heading";
import { useTheme } from "@mui/material/styles";
import fest1 from "../../assets/images/fest1.jpg";
import useMediaQuery from "@mui/material/useMediaQuery";
import infa1 from "../../assets/images/infa1.jpg";
import infa2 from "../../assets/images/infa2.jpg";
import infa3 from "../../assets/images/infa3.jpg";
import infa4 from "../../assets/images/infa4.jpg";

function ProductCraft() {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box sx={{ my: "80px" }}>
      <Heading
        title={"Our Product Journey"}
        subTitle="Explore the evolution of our products, from inception to perfection."
      />

      <Box
        sx={{ marginTop: "80px" }}
        // data-aos="fade-up"
      >
        <Grid
          container
          columnSpacing={{ md: 8, xs: 0 }}
          rowSpacing={{ md: 0, xs: 5 }}
          // spacing={3}
          direction={!matchesMd ? "column-reverse" : "row"}
          justifyContent="center"
          alignItems="center"
          sx={{ pb: 3 }}
        >
          <Grid
            item
            md={6}
            xs={12}
            data-aos="fade-right"
            // sx={{ pt: "10px !important" }}
          >
            <div className={``}>
              <Typography gutterBottom variant="h5">
                Manufacturing Infrastructure
              </Typography>
              <Typography gutterBottom variant="body1">
                Our manufacturing infrastructure is equipped with cutting-edge
                technology, enabling us to manufacture the best quality
                polyester films and PET resins with precision and efficiency. We
                have a manufacturing unit with a capacity of 90000 MT per annum,
                where we use our craft to produce products with exceptional
                properties. Our infrastructure is designed to support our
                backward integration strategy, enabling us to develop raw
                materials in-house, reducing our reliance on external suppliers.
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            data-aos="fade-right"
            // sx={{ pt: "10px !important" }}
          >
            <div className={`festCard parallax`}>
              <div className="festCardImg">
                <img
                  loading="lazy"
                  src={infa3}
                  alt=""
                  srcset=""
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px 10px 0 0",
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={{ md: 8, xs: 0 }}
          rowSpacing={{ md: 0, xs: 5 }}
          // spacing={3}
          //   direction={!matchesMd ? "column-reverse" : "row"}
          justifyContent="center"
          alignItems="center"
          sx={{ pb: 3 }}
        >
          <Grid
            item
            md={6}
            xs={12}
            data-aos="fade-right"
            // sx={{ pt: "10px !important" }}
          >
            <div className={`festCard parallax`}>
              <div className="festCardImg">
                <img
                  loading="lazy"
                  src={infa4}
                  alt=""
                  srcset=""
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px 10px 0 0",
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            data-aos="fade-right"
            // sx={{ pt: "10px !important" }}
          >
            <div className={``}>
              <Typography gutterBottom variant="h5" sx={{}}>
                Sustainability
              </Typography>
              <Typography gutterBottom variant="body1" sx={{}}>
                We believe in sustainable manufacturing practices and are
                committed to reducing our environmental footprint. Our backward
                integration strategy plays a significant role in this commitment
                as we convert post-consumer products such as bottles into flakes
                through a mechanical process and then into monomer through a
                chemical process to produce r-PET resin. This approach reduces
                the consumption of crude extracts and makes our entire process
                environmentally sustainable. Our manufacturing infrastructure is
                also designed to be energy-efficient, reducing our carbon
                footprint.
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={{ md: 8, xs: 0 }}
          rowSpacing={{ md: 0, xs: 5 }}
          // spacing={3}
          direction={!matchesMd ? "column-reverse" : "row"}
          justifyContent="center"
          alignItems="center"
          sx={{ pb: 3 }}
        >
          <Grid
            item
            md={6}
            xs={12}
            data-aos="fade-right"
            // sx={{ pt: "10px !important" }}
          >
            <div className={``}>
              <Typography gutterBottom variant="h5" sx={{}}>
                Innovation
              </Typography>
              <Typography gutterBottom variant="body1" sx={{}}>
                At Aksharlon, we believe in constantly innovating our craft to
                stay ahead of the curve. We invest in research and development
                to improve our manufacturing processes and develop new products
                that meet the evolving needs of our customers. Our commitment to
                innovation is evident in our ability to produce specialty films
                with unique properties that cater to specific applications. Our
                craft is a combination of tradition and innovation, enabling us
                to produce high-quality products that meet the highest standards
                of excellence.
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            data-aos="fade-right"
            // sx={{ pt: "10px !important" }}
          >
            <div className={`festCard parallax`}>
              <div className="festCardImg">
                <img
                  loading="lazy"
                  src={fest1}
                  alt=""
                  srcset=""
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px 10px 0 0",
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ProductCraft;
