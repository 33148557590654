import { Box, Container } from "@mui/material";
import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import SmallBanner from "../../components/smallBanner/SmallBanner";
import ProductCatalog from "../../components/productCatalog/ProductCatalog";
import productCatlog from "../../assets/images/productCatlog2.png";

function Products() {
  const navSteps = {
    first: { name: "Home", url: "/" },
    second: { name: "Products", url: "" },
  };

  return (
    <Box>
      {/* <div className="container"> */}
      <Navbar viewNav={true} />
      <SmallBanner
        title="Product Catalog"
        img={productCatlog}
        navigationSteps={navSteps}
      />
      <Container maxWidth="xl">
        <ProductCatalog />
      </Container>
      {/* </div> */}
      <Footer />
    </Box>
  );
}

export default Products;
