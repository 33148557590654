import {
  Box,
  Grid,
  Snackbar,
  SnackbarContent,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Heading from "../heading/Heading";
import { collection, addDoc, getDocs, where, query } from "firebase/firestore";
import "./contactus.css";
import { fireStore } from "../../firebase";

const Contactus = () => {
  const [state, setState] = React.useState(false);

  const handleClose = () => {
    setState(false);
  };
  const [contact, setContact] = useState({
    fname: "",
    lname: "",
    email: "",
    phoneNo: "",
    message: "",
  });

  const [error, setError] = useState({
    email: "",
    fname: "",
    phoneNo: "",
  });

  const onSubmit = async () => {
    if (!handleValidation(contact)) {
      try {
        const docRef = await addDoc(collection(fireStore, "contact"), contact);
        setState(true);
        setContact({
          fname: "",
          lname: "",
          email: "",
          phoneNo: "",
          message: "",
        });
      } catch (error) {
        console.log(error);
      }
    }
    // const dataObj = {
    //   id: 1,
    //   ...data,
    //   createdAt: new Date().toISOString(),
    //   updatedAt: new Date().toISOString(),
    // };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleValidationOnChange(name, value);
    setContact({ ...contact, [name]: value });
  };

  const handleValidationOnChange = (key, value) => {
    if (value !== "") {
      // errors[key] = "";
      setError({ ...error, [key]: "" });
    }
  };

  const handleValidation = (data) => {
    const errors = {};
    let err = false;
    if (
      contact.email === "" ||
      contact.fname === "" ||
      contact.phoneNo === ""
    ) {
      errors.email = contact.email === "" ? "Email is required" : "";
      errors.fname = contact.fname === "" ? "First Name is required" : "";
      errors.phoneNo = contact.phoneNo === "" ? "Phone No. is required" : "";
      setError(errors);
      err = true;
    }
    if (data.email) {
      const pattern =
        // eslint-disable-next-line
        /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
      const result = pattern.test(data.email);
      if (result === false) {
        errors.email = "Invalid Email";
        err = true;
      }
    }

    if (data.phoneNo) {
      // eslint-disable-next-line
      const pattern =
        // eslint-disable-next-line
        /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;
      if (pattern.test(data.phoneNo)) {
        if (data.phoneNo.length !== 10) {
          errors.phoneNo = "Please put 10  digit mobile number";
          err = true;
        }
      } else {
        errors.phoneNo = "Invalid mobile number";
        err = true;
      }
    }

    setError(errors);
    return err;
  };

  return (
    <>
      <Box my={10}>
        {/* <Heading title={"contact us"} subTitle="" /> */}
        <Box my={7}>
          <div className="contact_us_2">
            <div className="responsive-container-block big-container">
              <div className="blueBG"></div>
              <div className="responsive-container-block container">
                <div className="form-box">
                  <div className="container-block form-wrapper">
                    <p className="text-blk contactus-head">Contact us</p>
                    <p className="text-blk contactus-subhead">
                      Nunc erat cursus tellus gravida.
                    </p>
                    <div className="responsive-container-block">
                      <div
                        className="responsive-cell-block wk-ipadp-6 wk-tab-12 wk-mobile-12 wk-desk-6"
                        id="i10mt"
                      >
                        <p className="text-blk input-title">FIRST NAME</p>
                        <input
                          className="input"
                          id="ijowk"
                          name="fname"
                          placeholder="Please enter first name..."
                          onChange={handleChange}
                          value={contact.fname}
                        />
                        {error.fname && (
                          <div className="invalid-error">
                            {" "}
                            Please enter your first name.{" "}
                          </div>
                        )}
                      </div>
                      <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                        <p className="text-blk input-title">LAST NAME</p>
                        <input
                          className="input"
                          id="indfi"
                          name="lname"
                          placeholder="Please enter last name..."
                          onChange={handleChange}
                          value={contact.lname}
                        />
                      </div>
                      <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                        <p className="text-blk input-title">EMAIL</p>
                        <input
                          className="input"
                          id="ipmgh"
                          name="email"
                          placeholder="Please enter email..."
                          onChange={handleChange}
                          value={contact.email}
                        />
                        {error.email && (
                          <div className="invalid-error">
                            {" "}
                            Please provide a valid email address.{" "}
                          </div>
                        )}
                      </div>
                      <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                        <p className="text-blk input-title">PHONE NUMBER</p>
                        <input
                          className="input"
                          id="imgis"
                          name="phoneNo"
                          placeholder="Please enter phone number..."
                          onChange={handleChange}
                          value={contact.phoneNo}
                        />
                        {error.phoneNo && (
                          <div className="invalid-error">
                            {" "}
                            Please provide a valid phone number.{" "}
                          </div>
                        )}
                      </div>
                      <div
                        className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12"
                        id="i634i"
                      >
                        <p className="text-blk input-title">MASSAGE</p>
                        <textarea
                          className="textinput"
                          id="i5vyy"
                          placeholder="Please enter query..."
                          name="message"
                          onChange={handleChange}
                          value={contact.message}
                        ></textarea>
                      </div>
                    </div>
                    <button
                      className="submit-btn"
                      onClick={(e) => {
                        onSubmit();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={state}
          onClose={handleClose}
          // message="Submitted successfully!"
          key={"top" + "center"}
        >
          <SnackbarContent
            style={{
              backgroundColor: "#077207",
            }}
            message={<span id="client-snackbar">Submitted successfully!</span>}
          />
        </Snackbar>
      </Box>
    </>
  );
};

export default Contactus;
