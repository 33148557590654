import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import Heading from "../heading/Heading";
import { collection, getDocs } from "firebase/firestore";
import { fireStore } from "../../firebase";

function Exhibition() {
  const [events, setEvents] = React.useState([]);

  async function getEvents() {
    const querySnapshot = await getDocs(collection(fireStore, "exhibition"));
    //   console.log(querySnapshot);
    let event = [];

    if (querySnapshot) {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        event.push({ ...data, id: doc.id });
      });
    }
    setEvents([...event]);
  }

  useEffect(() => {
    getEvents();
  }, []);

  const bgColors = ["#192257", "#6765B5", "#000000"];

  return (
    <Box sx={{ my: "80px" }}>
      <Heading title={"Our Exhibition"} subTitle="" />
      {events?.length > 0 ? (
        <Box sx={{ mt: 5 }} data-aos="fade-up">
          {events.map((data, i) => {
            return (
              <Grid
                container
                columnSpacing={2}
                direction={{
                  md: i % 2 === 0 ? "row" : "row-reverse",
                  xs: "column-reverse",
                }}
                // justifyContent="center"
                //   alignItems="center"
                sx={{ backgroundColor: bgColors[i % bgColors.length] }}
              >
                <Grid
                  item
                  md={5}
                  sx={{ color: "#fff", display: "grid", placeItems: "center" }}
                >
                  <Box
                    sx={{
                      width: { md: "73%", xs: "100%" },
                      ml: i % 2 === 0 ? "auto" : "none",
                      mr: i % 2 === 1 ? "auto" : "none",
                      p: { md: 0, xs: 2 },
                    }}
                  >
                    <Typography gutterBottom variant="h5">
                      {data?.title}
                    </Typography>
                    <Typography gutterBottom variant="body1" color={"#d2d2d2"}>
                      {data?.description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={7}>
                  <Box sx={{ height: "390.34px" }}>
                    <img
                      loading="lazy"
                      src={data?.fileUrl}
                      alt=""
                      width={"100%"}
                      height={"100%"}
                    />
                  </Box>
                </Grid>
              </Grid>
            );
          })}

          {/* <Grid
          container
          columnSpacing={2}
          direction={{ md: "row", xs: "column-reverse" }}
          // justifyContent="center"
          //   alignItems="center"
          sx={{ backgroundColor: "#192257" }}
        >
          <Grid
            item
            md={5}
            sx={{ color: "#fff", display: "grid", placeItems: "center" }}
          >
            <Box
              sx={{
                width: { md: "73%", xs: "100%" },
                ml: "auto",
                p: { md: 0, xs: 2 },
              }}
            >
              <Typography gutterBottom variant="h5">
                Exhibition of 2021
              </Typography>
              <Typography gutterBottom variant="body1" color={"#d2d2d2"}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Perferendis, nam mollitia dolor eius vitae eos sapiente optio
                est praesentium? Exercitationem necessitatibus suscipit expedita
                quod beatae voluptate fuga quam illo molestiae.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={7}>
            <Box sx={{ height: "390.34px" }}>
              <img loading="lazy"
                src="https://www.moma.org/d/assets/W1siZiIsIjIwMjMvMDcvMjcvdXlhZHhnNjUwXzdfMjVfMjNfQ29udGVtcG9yYXJ5X0FydF9pbl90aGVfR2FyZGVuX0hvbWVwYWdlX0hlcm9fRmluYWwuanBnIl0sWyJwIiwiY29udmVydCIsIi1xdWFsaXR5IDkwIC1yZXNpemUgMTMwMHg0MDBeIC1ncmF2aXR5IENlbnRlciAtY3JvcCAxMzAweDQwMCswKzAiXV0/7-25-23_Contemporary-Art-in-the-Garden_Homepage-Hero_Final.jpg?sha=7f39277cabe43768"
                alt=""
                width={"100%"}
                height={"100%"}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={2}
          // direction={{ md: "row", xs: "column-reverse" }}
          // justifyContent="center"
          //   alignItems="center"
          sx={{ backgroundColor: "#6765B5" }}
        >
          <Grid item md={7}>
            <Box sx={{ height: "390.34px" }}>
              <img loading="lazy"
                src="https://www.moma.org/d/assets/W1siZiIsIjIwMjIvMTEvMDQvMWxjYmlqejFrYV9QUzFfQWVyaWFsX0J1aWxkaW5nX1Nob3RfRURJVEVEX0Nvb2xfUENfTm9lbF9Xb29kZm9yZF8yNzI5XzFfLmpwZyJdLFsicCIsImNvbnZlcnQiLCItcXVhbGl0eSA5MCAtcmVzaXplIDEzMDB4NDAwXiAtZ3Jhdml0eSBTb3V0aCAtY3JvcCAxMzAweDQwMCswKzQ4Il1d/PS1_Aerial%20Building%20Shot_EDITED_Cool_PC-Noel%20Woodford-2729%20%281%29.jpg?sha=e4e824f118e9c13f"
                alt=""
                width={"100%"}
                height={"100%"}
              />
            </Box>
          </Grid>
          <Grid
            item
            md={5}
            sx={{ color: "#fff", display: "grid", placeItems: "center" }}
          >
            <Box
              sx={{
                width: { md: "73%", xs: "100%" },
                mr: "auto",
                p: { md: 0, xs: 2 },
              }}
            >
              <Typography gutterBottom variant="h5">
                Exhibition of 2022
              </Typography>
              <Typography gutterBottom variant="body1" color={"#d2d2d2"}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Perferendis, nam mollitia dolor eius vitae eos sapiente optio
                est praesentium? Exercitationem necessitatibus suscipit expedita
                quod beatae voluptate fuga quam illo molestiae.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={2}
          direction={{ md: "row", xs: "column-reverse" }}
          // justifyContent="center"
          //   alignItems="center"
          sx={{ backgroundColor: "#000000" }}
        >
          <Grid
            item
            md={5}
            sx={{ color: "#fff", display: "grid", placeItems: "center" }}
          >
            <Box
              sx={{
                width: { md: "73%", xs: "100%" },
                ml: "auto",
                p: { md: 0, xs: 2 },
              }}
            >
              <Typography gutterBottom variant="h5">
                Exhibition of 2023
              </Typography>
              <Typography gutterBottom variant="body1" color={"#d2d2d2"}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Perferendis, nam mollitia dolor eius vitae eos sapiente optio
                est praesentium? Exercitationem necessitatibus suscipit expedita
                quod beatae voluptate fuga quam illo molestiae.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={7}>
            <Box sx={{ height: "390.34px" }}>
              <img loading="lazy"
                src="https://www.moma.org/d/assets/W1siZiIsIjIwMjMvMDcvMjcvdXlhZHhnNjUwXzdfMjVfMjNfQ29udGVtcG9yYXJ5X0FydF9pbl90aGVfR2FyZGVuX0hvbWVwYWdlX0hlcm9fRmluYWwuanBnIl0sWyJwIiwiY29udmVydCIsIi1xdWFsaXR5IDkwIC1yZXNpemUgMTMwMHg0MDBeIC1ncmF2aXR5IENlbnRlciAtY3JvcCAxMzAweDQwMCswKzAiXV0/7-25-23_Contemporary-Art-in-the-Garden_Homepage-Hero_Final.jpg?sha=7f39277cabe43768"
                alt=""
                width={"100%"}
                height={"100%"}
              />
            </Box>
          </Grid>
        </Grid> */}
        </Box>
      ) : (
        <Box sx={{ display: "grid", placeItems: "center", mt: 5 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export default Exhibition;
