import React, { useEffect } from "react";
import Heading from "../heading/Heading";
import { Box, CircularProgress, Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import EventCard from "./card/EventCard";
import eventThumbnail from "../../assets/images/eventThumbnail.jpg";
import {
  collection,
  addDoc,
  getDocs,
  where,
  query,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { fireStore, fireStorage } from "../../firebase";

function Event() {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));

  const [events, setEvents] = React.useState([]);

  async function getEvents() {
    const querySnapshot = await getDocs(collection(fireStore, "event"));
    //   console.log(querySnapshot);
    let event = [];

    if (querySnapshot) {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        event.push({ ...data, id: doc.id });
      });
    }
    setEvents([...event]);
  }

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <Box sx={{ my: "80px" }}>
      <Heading title={"Our Events"} subTitle="" />

      {events?.length > 0 ? (
        <Box
          sx={{ marginTop: "30px", display: "flex", justifyContent: "center" }}
          data-aos="fade-up"
        >
          <Grid
            container
            spacing={5}
            direction={!matchesMd ? "column" : "row"}
            // justifyContent="center"
            alignItems="center"
          >
            {events?.map((data) => {
              return (
                <Grid item lg={4} md={6}>
                  <EventCard imgUrl={data?.fileUrl} title={data?.title} />
                </Grid>
              );
            })}
            {/* 
          <Grid item lg={4} md={6}>
            <EventCard
              imgUrl={eventThumbnail}
              title={"Aksharlon Jari's Corporate Event"}
            />
          </Grid>
          <Grid item lg={4} md={6}>
            <EventCard
              imgUrl={eventThumbnail}
              title={"Aksharlon Jari's Corporate Event"}
            />
          </Grid>
          <Grid item lg={4} md={6}>
            <EventCard
              imgUrl={eventThumbnail}
              title={"Aksharlon Jari's Corporate Event"}
            />
          </Grid>
          <Grid item lg={4} md={6}>
            <EventCard
              imgUrl={eventThumbnail}
              title={"Aksharlon Jari's Corporate Event"}
            />
          </Grid>
          <Grid item lg={4} md={6}>
            <EventCard
              imgUrl={eventThumbnail}
              title={"Aksharlon Jari's Corporate Event"}
            />
          </Grid> */}
          </Grid>
        </Box>
      ) : (
        <Box sx={{ display: "grid", placeItems: "center", mt: 5 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export default Event;
