import { Box, Container } from "@mui/material";
import React from "react";
import BioGraphy from "../../components/bioGraphy/BioGraphy";
import Certificate from "../../components/certificate/Certificate";
import Contactus from "../../components/contactus/Contactus";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import SmallBanner from "../../components/smallBanner/SmallBanner";

const About = () => {
  const navSteps = {
    first: { name: "Home", url: "/" },
    second: { name: "About Us", url: "" },
  };
  return (
    <Box>
      <Navbar viewNav={true} />
      <SmallBanner title="About us" navigationSteps={navSteps} />
      <Container maxWidth="xl">
        <BioGraphy />
      </Container>
      <Container maxWidth="xl">
        <Certificate />
      </Container>
      <Container maxWidth="xl">
        <Contactus />
      </Container>
      <Footer />
    </Box>
  );
};

export default About;
