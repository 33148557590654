import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import "./footer.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link, useLocation, useNavigate } from "react-router-dom";
import footerLogo from "../../assets/images/AksharlonIndustriesWhite.png";

function Footer() {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  const location = useLocation();
  const navigate = useNavigate();

  const handlePages = () => {
    const page = location.pathname;

    if (page !== "/") {
      navigate("/");
    }
  };

  return (
    <footer>
      <Container maxWidth="xl">
        <div>
          <Grid
            container
            spacing={2}
            // direction={!matchesMd ? "column" : "row"}
            // textAlign={!matchesMd ? "center" : "left"}
            alignItems="start"
          >
            <Grid item md={3} xs={12} textAlign={{ md: "left", xs: "center" }}>
              <div>
                <img
                  loading="lazy"
                  src={footerLogo}
                  alt=""
                  width={205}
                  height={140}
                />
              </div>
              {/* <div>
                <Typography variant="body2" color={"#d2d2d2"}>
                  Aksharlon Jari specializes in embroidery, metallic colored
                  yarn, and cording threads.
                </Typography>
              </div> */}
            </Grid>
            <Grid item md={3} sm={4} xs={6}>
              <Typography
                variant={"subtitle1"}
                component="div"
                color={"#fff"}
                my={1}
              >
                Company
              </Typography>
              <ul className="footer-ul">
                <li className="">
                  <Link to="/">Home</Link>
                </li>
                <li className="">
                  <Link to="/products">Products</Link>
                </li>
                <li className="">
                  <a href="#infrastructure" onClick={handlePages}>
                    Infrastructure
                  </a>
                </li>
                <li className="">
                  <a href="#distribution" onClick={handlePages}>
                    Distribution
                  </a>
                </li>
              </ul>
            </Grid>
            <Grid item md={3} sm={4} xs={6}>
              <Typography
                variant={"subtitle1"}
                component="div"
                color={"#fff"}
                my={1}
              >
                About Us
              </Typography>
              <ul className="footer-ul">
                <li className="">
                  <Link to="/about-us">About us</Link>
                </li>
                <li className="">
                  <Link to="/gallery">Gallery</Link>
                </li>
                <li className="">
                  <Link to="/the-craft">The Craft</Link>
                </li>
                <li className="">
                  <Link to="/18+">18+ Event</Link>
                </li>
              </ul>
            </Grid>
            <Grid item md={3} sm={4}>
              <Typography
                variant={"subtitle1"}
                component="div"
                color={"#fff"}
                my={1}
              >
                CONTACT US
              </Typography>
              <ul className="footer-ul">
                <li className="">
                  <a
                    href=""
                    style={{
                      display: "flex",
                      gap: "8px",
                      // justifyContent: !matchesMd ? "center" : "flex-start",
                    }}
                  >
                    <CallOutlinedIcon fontSize="small" />
                    <span> +91 98258 26240</span>
                  </a>
                </li>
                <li className="">
                  <a
                    href=""
                    style={{
                      display: "flex",
                      gap: "8px",
                      // justifyContent: !matchesMd ? "center" : "flex-start",
                    }}
                  >
                    <CallOutlinedIcon fontSize="small" />
                    <span> +91 93776 98875</span>
                  </a>
                </li>
                <li className="">
                  <a
                    href=""
                    style={{
                      display: "flex",
                      gap: "8px",
                      // justifyContent: !matchesMd ? "center" : "flex-start",
                    }}
                  >
                    <CallOutlinedIcon fontSize="small" />
                    <span> +91 83205 37914</span>
                  </a>
                </li>
                <li className="">
                  <a
                    href=""
                    style={{
                      display: "flex",
                      gap: "8px",
                      // justifyContent: !matchesMd ? "center" : "flex-start",
                    }}
                  >
                    <LocationOnOutlinedIcon fontSize="small" />
                    F-2,5 to 8, Bhagwati Nagar Ind., Nr.Navin Fluorine Co.,
                    Bhestan, Surat - 395023 Gujarat India.
                  </a>
                </li>
              </ul>
            </Grid>
          </Grid>
        </div>

        <Box>
          <Divider sx={{ borderColor: "rgb(193 193 193 / 16%)", my: 2 }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: { md: "space-between", xs: "center" },
              alignItems: "center",
              flexDirection: { md: "column-reverse", xs: "column-reverse" },
              gap: { md: 2, xs: 2 },
            }}
          >
            <Typography
              variant="body2"
              color={"#d2d2d2"}
              sx={{ textAlign: "center" }}
            >
              © Copyright 2023 Aksharlon Jari. All rights reserved.
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              <Box
                color={"#d2d2d2"}
                sx={{
                  backgroundColor: "#343a66",
                  p: 1,
                  display: "grid",
                  placeItems: "center",
                  cursor: "pointer",
                  borderRadius: 2,
                  ":hover": {
                    backgroundColor: "#1873EB",
                  },
                }}
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/profile.php?id=61559670989540"
                  )
                }
              >
                <FacebookOutlinedIcon />
              </Box>
              <Box
                color={"#d2d2d2"}
                sx={{
                  backgroundColor: "#343a66",
                  p: 1,
                  display: "grid",
                  placeItems: "center",
                  cursor: "pointer",
                  borderRadius: 2,
                  ":hover": {
                    background:
                      "radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)",
                  },
                }}
                onClick={() =>
                  window.open("https://www.instagram.com/aksharlon_industries")
                }
              >
                <InstagramIcon />
              </Box>
              <Box
                color={"#d2d2d2"}
                sx={{
                  backgroundColor: "#343a66",
                  p: 1,
                  display: "grid",
                  placeItems: "center",
                  cursor: "pointer",
                  borderRadius: 2,
                  ":hover": {
                    backgroundColor: "#1873EB",
                  },
                }}
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/aksharlon-industries-pvt-ltd/"
                  )
                }
              >
                <LinkedInIcon />
              </Box>
              <Box
                color={"#d2d2d2"}
                sx={{
                  backgroundColor: "#343a66",
                  p: 1,
                  display: "grid",
                  placeItems: "center",
                  cursor: "pointer",
                  borderRadius: 2,
                  ":hover": {
                    backgroundColor: "#d2d2d2",
                    color: "red",
                  },
                }}
                onClick={() =>
                  window.open("https://www.youtube.com/@aksharlonjari5044")
                }
              >
                <YouTubeIcon />
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </footer>
  );
}

export default Footer;
