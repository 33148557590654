import React, { useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import videoInfa from "../../assets/videos/infastrucutre.webm";
import { Box, CircularProgress } from "@mui/material";
import Heading from "../heading/Heading";
import { collection, getDocs } from "firebase/firestore";
import { fireStore } from "../../firebase";

function Factory() {
  const matches = useMediaQuery("(max-width:992px)");
  const [events, setEvents] = React.useState({});

  async function getEvents() {
    const querySnapshot = await getDocs(collection(fireStore, "factory"));
    //   console.log(querySnapshot);
    let event = [];

    if (querySnapshot) {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        setEvents({ ...data });
      });
    }
    // setEvents([...event]);
  }

  useEffect(() => {
    getEvents();
  }, []);
  return (
    <Box sx={{ my: "80px" }}>
      <Heading title={"Our Factory"} subTitle="" />

      {events ? (
        <Box
          sx={{ marginTop: "30px" }}
          // data-aos="fade-up"
        >
          <Box
            sx={{
              // height: matches ? "auto" : "512px",
              width: matches ? "90%" : "90%",
              mx: "auto",
              // position: "absolute",
              // top: matches ? "-110px" : "-283px",
              // left: "50%",
              // transform: "translate(-50%)",
            }}
          >
            <video
              controls
              width={"100%"}
              height={"100%"}
              style={{ borderRadius: "12px" }}
              key={events?.videoUrl}
            >
              <source
                src={events ? events?.videoUrl : videoInfa}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "grid", placeItems: "center", mt: 5 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export default Factory;
