/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Tooltip, Typography, withStyles } from "@mui/material";
import React from "react";
import "./worldmap.css";
import Heading from "../heading/Heading";

const WorldMap = () => {
  return (
    <>
      <div id="distribution" className="our-presence">
        <div>
          <div className="our-presence-mai-div">
            <Heading
              title={"Our Distribution"}
              subTitle={
                "We're in 86+ countries worldwide, expanding on the trust our customers place in our commitment to top-tier quality."
              }
            />
            {/* <div className="title-div">
              <div
                data-w-id="c207bfdf-9dc9-9109-b7ad-9efb8b0f4fca"
                // style={{ opacity: 0 }}
                className="sub-title-div"
              >
                <div className="sub-title-line" />

                <h2 className="heading" />
                <div className="sub-title-line" />
              </div>
              <h1
                data-w-id="c207bfdf-9dc9-9109-b7ad-9efb8b0f4fcf"
                // style={{ opacity: 0 }}
                className="entities-and-brands-title"
              >
                Our Presence
              </h1>
              <p
                data-w-id="c207bfdf-9dc9-9109-b7ad-9efb8b0f4fd1"
                // style={{ opacity: 0 }}
                className="sub-paragraph"
              >
                We have a global presence in over{" "}
                <strong className="bold-text-6">86 countries</strong> across the
                world. We continue to expand with the trust, our customers have
                in our promise of delivering quality to the highest
                international standards.
              </p>
            </div> */}
            <div
              data-w-id="959f5ae3-ce4f-4bf3-56c2-6205a15152c8"
              // style={{ opacity: 0 }}
              className="map-main-div"
              style={{
                position: "relative",
                width: "80%",
                margin: "30px auto 0 auto",
                marginTop: "30px",
              }}
            >
              <img
                loading="lazy"
                src="https://uploads-ssl.webflow.com/63aebd8a8792ab50b7a132a3/63c53146afad0b5dcee9bdc2_MAP.svg"
                alt="hare krishna diamond Our Presence"
                style={{ width: "100%" }}
              />

              <Tooltip
                title={<Typography>India</Typography>}
                placement="top-start"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "common.white",
                      "& .MuiTooltip-arrow": {
                        color: "common.black",
                      },
                      color: "black",
                    },
                  },
                }}
              >
                <a
                  data-w-id="45864da5-7299-c74d-8a02-7590db11a61c"
                  href="#"
                  className="map-locations india w-inline-block"
                ></a>
              </Tooltip>

              <Tooltip
                title={<Typography>Europe</Typography>}
                placement="top-start"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "common.white",
                      "& .MuiTooltip-arrow": {
                        color: "common.black",
                      },
                      color: "black",
                    },
                  },
                }}
              >
                <a
                  data-w-id="45864da5-7299-c74d-8a02-7590db11a61c"
                  href="#"
                  className="map-locations europe w-inline-block"
                ></a>
              </Tooltip>
              <Tooltip
                title={<Typography>Pakistan</Typography>}
                placement="top-start"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "common.white",
                      "& .MuiTooltip-arrow": {
                        color: "common.black",
                      },
                      color: "black",
                    },
                  },
                }}
              >
                <a
                  data-w-id="45864da5-7299-c74d-8a02-7590db11a61c"
                  href="#"
                  className="map-locations pakistan w-inline-block"
                ></a>
              </Tooltip>

              <Tooltip
                title={<Typography>Sri Lanka</Typography>}
                placement="top-start"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "common.white",
                      "& .MuiTooltip-arrow": {
                        color: "common.black",
                      },
                      color: "black",
                    },
                  },
                }}
              >
                <a
                  data-w-id="203f403d-5d1c-453c-ad8b-4507255d7868"
                  href="#"
                  className="map-locations srilanka w-inline-block"
                ></a>
              </Tooltip>

              <Tooltip
                title={<Typography>Vietnam</Typography>}
                placement="top-start"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "common.white",
                      "& .MuiTooltip-arrow": {
                        color: "common.white",
                      },
                      color: "black",
                    },
                  },
                }}
              >
                <a
                  data-w-id="203f403d-5d1c-453c-ad8b-4507255d7868"
                  href="#"
                  className="map-locations vietnam w-inline-block"
                ></a>
              </Tooltip>
              <Tooltip
                title={<Typography>West Bengal</Typography>}
                placement="top-start"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "common.white",
                      "& .MuiTooltip-arrow": {
                        color: "common.black",
                      },
                      color: "black",
                    },
                  },
                }}
              >
                <a
                  data-w-id="203f403d-5d1c-453c-ad8b-4507255d7868"
                  href="#"
                  className="map-locations westBengal w-inline-block"
                ></a>
              </Tooltip>

              <Tooltip
                title={<Typography>Bangladesh</Typography>}
                placement="top-start"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "common.white",
                      "& .MuiTooltip-arrow": {
                        color: "common.black",
                      },
                      color: "black",
                    },
                  },
                }}
              >
                <a
                  data-w-id="203f403d-5d1c-453c-ad8b-4507255d7868"
                  href="#"
                  className="map-locations bangladesh w-inline-block"
                ></a>
              </Tooltip>

              <Tooltip
                title={<Typography>Turkey</Typography>}
                placement="top-start"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "common.white",
                      "& .MuiTooltip-arrow": {
                        color: "common.black",
                      },
                      color: "black",
                    },
                  },
                }}
              >
                <a
                  data-w-id="3a4690d7-f8bf-02df-188e-4c369f4205b8"
                  href="#"
                  className="map-locations turkey w-inline-block"
                ></a>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorldMap;
