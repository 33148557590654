import { Box, Container, TableContainer } from "@mui/material";
import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import SmallBanner from "../../components/smallBanner/SmallBanner";
import event from "../../assets/images/event.png";
import Event from "../../components/event/Event";
import Festival from "../../components/festival/Festival";
import SocialWorks from "../../components/socialWorks/SocialWorks";
import Exhibition from "../../components/exhibition/Exhibition";
import Factory from "../../components/factory/Factory";

function Gallery() {
  const navSteps = {
    first: { name: "Home", url: "/" },
    second: { name: "Gallery", url: "" },
  };
  return (
    <Box>
      {/* <div className="container"> */}
      <Navbar viewNav={true} />
      {/* </div> */}
      <SmallBanner title="Our Gallery" img={event} navigationSteps={navSteps} />
      <Container maxWidth="xl">
        <Event />
      </Container>
      <Festival />
      <Container maxWidth="xl">
        <SocialWorks />
      </Container>
      <Exhibition />
      <Container maxWidth="xl">
        <Factory />
      </Container>
      <Footer />
    </Box>
  );
}

export default Gallery;
