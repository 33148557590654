import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import videoInfa from "../../assets/videos/infastrucutre.webm";
import Heading from "../heading/Heading";
import useMediaQuery from "@mui/material/useMediaQuery";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import { collection, getDocs } from "firebase/firestore";
import { fireStore } from "../../firebase";

function Infrastructure() {
  const videoRef = useRef();
  const [videoPlay, setVideoPlay] = useState(false);
  const matches = useMediaQuery("(max-width:992px)");
  // const matches = useMediaQuery("(max-width:992px)");

  const [events, setEvents] = React.useState({});

  async function getEvents() {
    const querySnapshot = await getDocs(collection(fireStore, "video"));
    //   console.log(querySnapshot);
    let event = [];

    if (querySnapshot) {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        setEvents({ ...data });
      });
    }
    // setEvents([...event]);
  }

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <Box sx={{ my: "80px" }} id="infrastructure">
      <Heading
        title={"Our Infrastructure"}
        subTitle={
          "Our Central Infrastructure Hub, where innovation meets sustainability for a brighter community future."
        }
      />
      {/* <Box mt={2}>
        <button class="learn-more">
          <span class="circle" aria-hidden="true">
            <span class="icon arrow"></span>
          </span>
          <span class="button-text" style={{ color: "black" }}>
            Find Out More
          </span>
        </button>
      </Box> */}
      {/* <Box>
        <Typography
          variant="body"
          gutterBottom
          color={"rgb(96, 105, 123)"}
          my={2}
        >
          We have all facilities in-house to provide best quality imitation
          jari. Our dedicated workforce consists of skilled professionals
          working in various capacities in various departments such as
          Production, Administration, Finance, Marketing and others. All working
          under the able guidance of our promoters.
        </Typography>
        <br />
        <Typography variant="body" gutterBottom color={"rgb(96, 105, 123)"}>
          We have all facilities in-house to provide best quality imitation
          jari. Our dedicated workforce consists of skilled professionals
          working in various capacities in various departments such as
          Production, Administration, Finance, Marketing and others. All working
          under the able guidance of our promoters.
        </Typography>
      </Box> */}

      <Box
        sx={{
          mt: { sm: 40, xs: 10 },
          position: "relative",
          backgroundColor: "#192257",
          height: { md: "300px", xs: "200px" },
          borderRadius: "6px",
        }}
        data-aos="fade-up"
      >
        {events ? (
          <Box
            sx={{
              height: matches ? "auto" : "512px",
              width: matches ? "90%" : "890px",
              mx: "auto",
              position: "absolute",
              // top: matches ? "-110px" : "-283px",
              bottom: "50px",
              left: "50%",
              transform: "translate(-50%)",
            }}
            className="infaVideo"
          >
            {!videoPlay && (
              <Box
                sx={{
                  backgroundColor: "#ffffff42",
                  color: "#fff",
                  p: 2,
                  zIndex: 99,
                  borderRadius: "100%",
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "black",
                  },
                }}
                onClick={() => {
                  videoRef.current.play();
                  setVideoPlay(true);
                  console.log("videoRef.current", videoRef.current.paused);
                }}
              >
                <PlayArrowOutlinedIcon />
              </Box>
            )}
            {videoPlay && (
              <Box
                sx={{
                  backgroundColor: "#ffffff42",
                  color: "#fff",
                  p: 2,
                  zIndex: 99,
                  borderRadius: "100%",
                  position: "absolute",
                  display: "none",
                  justifyContent: "center",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "black",
                  },
                }}
                onClick={() => {
                  videoRef.current.pause();
                  setVideoPlay(false);
                  console.log("videoRef.current", videoRef.current.paused);
                }}
                className="pauseVideo"
              >
                <PauseOutlinedIcon />
              </Box>
            )}
            <video
              width={"100%"}
              height={"100%"}
              style={{ borderRadius: "12px" }}
              ref={videoRef}
              key={events?.videoUrl}
            >
              <source
                src={events ? events?.videoUrl : videoInfa}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </Box>
        ) : (
          <Box sx={{ display: "grid", placeItems: "center", mt: 5 }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Infrastructure;
