import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import Heading from "../heading/Heading";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import "./festival.css";
import fest1 from "../../assets/images/fest1.jpg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { collection, getDocs } from "firebase/firestore";
import { fireStore } from "../../firebase";

function Festival() {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  const [events, setEvents] = React.useState([]);

  async function getEvents() {
    const querySnapshot = await getDocs(collection(fireStore, "festivals"));
    //   console.log(querySnapshot);
    let event = [];

    if (querySnapshot) {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        event.push({ ...data, id: doc.id });
      });
    }
    setEvents([...event]);
  }

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <Box sx={{ my: "80px", py: 10 }} className="background">
      <Container maxWidth="xl">
        <Box>
          {/* <Heading title={"Our Festivals"} /> */}
          {events?.length > 0 ? (
            <Box
            // sx={{ marginTop: "30px", display: "flex", justifyContent: "center" }}
            // data-aos="fade-up"
            >
              {events.map((data, i) => {
                return (
                  <Grid
                    container
                    columnSpacing={{ md: 8, xs: 0 }}
                    rowSpacing={{ md: 0, xs: 5 }}
                    // spacing={3}
                    direction={
                      !matchesMd
                        ? "column-reverse"
                        : i % 2 === 0
                        ? "row"
                        : "row-reverse"
                    }
                    justifyContent="center"
                    alignItems="center"
                    sx={{ pb: 3 }}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                      data-aos="fade-right"
                      // sx={{ pt: "10px !important" }}
                    >
                      <div className={``}>
                        <Typography
                          gutterBottom
                          variant="h5"
                          sx={{ color: "#fff" }}
                        >
                          {data?.title}
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="body1"
                          sx={{ color: "#d2d2d2" }}
                        >
                          {data?.description}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                      data-aos="fade-right"
                      // sx={{ pt: "10px !important" }}
                    >
                      <div className={`festCard parallax`}>
                        <div className="festCardImg">
                          <img
                            loading="lazy"
                            src={data?.fileUrl}
                            alt=""
                            srcset=""
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "10px 10px 0 0",
                            }}
                          />
                        </div>
                        <div className="festCardText">
                          <p>{data?.imgTitle}</p>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                );
              })}

              {/* <Grid
              container
              columnSpacing={{ md: 8, xs: 0 }}
              rowSpacing={{ md: 0, xs: 5 }}
              // spacing={3}
              direction={!matchesMd ? "column-reverse" : "row"}
              justifyContent="center"
              alignItems="center"
              sx={{ pb: 3 }}
            >
              <Grid
                item
                md={6}
                xs={12}
                data-aos="fade-right"
                // sx={{ pt: "10px !important" }}
              >
                <div className={``}>
                  <Typography gutterBottom variant="h5" sx={{ color: "#fff" }}>
                    Festivals of 2023
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body1"
                    sx={{ color: "#d2d2d2" }}
                  >
                    At Aksharlon, we believe that festivals are not just
                    gatherings but opportunities to build connections and
                    strengthen communities. Our festivals are inclusive spaces
                    where people of all backgrounds come together to share in
                    the joys of life, fostering bonds that go beyond the event
                    itself.
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                data-aos="fade-right"
                // sx={{ pt: "10px !important" }}
              >
                <div className={`festCard parallax`}>
                  <div className="festCardImg">
                    <img loading="lazy"
                      src={fest1}
                      alt=""
                      srcset=""
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px 10px 0 0",
                      }}
                    />
                  </div>
                  <div className="festCardText">
                    <p>Festival 2023</p>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              columnSpacing={{ md: 8, xs: 0 }}
              rowSpacing={{ md: 0, xs: 5 }}
              // spacing={3}
              // direction={!matchesMd ? "column-reverse" : "row"}
              justifyContent="center"
              alignItems="center"
              sx={{ pb: 3 }}
            >
              <Grid
                item
                md={6}
                data-aos="fade-right"
                // sx={{ pt: "10px !important" }}
              >
                <div className={`festCard parallax`}>
                  <div className="festCardImg">
                    <img loading="lazy"
                      src={fest1}
                      alt=""
                      srcset=""
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px 10px 0 0",
                      }}
                    />
                  </div>
                  <div className="festCardText">
                    <p>Festival 2022</p>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                md={6}
                data-aos="fade-right"
                // sx={{ pt: "10px !important" }}
              >
                <div className={``}>
                  <Typography gutterBottom variant="h5" sx={{ color: "#fff" }}>
                    Festivals of 2022
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body1"
                    sx={{ color: "#d2d2d2" }}
                  >
                    At Aksharlon, we believe that festivals are not just
                    gatherings but opportunities to build connections and
                    strengthen communities. Our festivals are inclusive spaces
                    where people of all backgrounds come together to share in
                    the joys of life, fostering bonds that go beyond the event
                    itself.
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              columnSpacing={{ md: 8, xs: 0 }}
              rowSpacing={{ md: 0, xs: 5 }}
              // spacing={3}
              direction={!matchesMd ? "column-reverse" : "row"}
              justifyContent="center"
              alignItems="center"
              sx={{ pb: 3 }}
            >
              <Grid
                item
                md={6}
                data-aos="fade-right"
                // sx={{ pt: "10px !important" }}
              >
                <div className={``}>
                  <Typography gutterBottom variant="h5" sx={{ color: "#fff" }}>
                    Festivals of 2021
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body1"
                    sx={{ color: "#d2d2d2" }}
                  >
                    At Aksharlon, we believe that festivals are not just
                    gatherings but opportunities to build connections and
                    strengthen communities. Our festivals are inclusive spaces
                    where people of all backgrounds come together to share in
                    the joys of life, fostering bonds that go beyond the event
                    itself.
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                md={6}
                data-aos="fade-right"
                // sx={{ pt: "10px !important" }}
              >
                <div className={`festCard parallax`}>
                  <div className="festCardImg">
                    <img loading="lazy"
                      src={fest1}
                      alt=""
                      srcset=""
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px 10px 0 0",
                      }}
                    />
                  </div>
                  <div className="festCardText">
                    <p>Festival 2021</p>
                  </div>
                </div>
              </Grid>
            </Grid> */}
            </Box>
          ) : (
            <Box sx={{ display: "grid", placeItems: "center", mt: 5 }}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
}

export default Festival;
