// import React, { useEffect, useState } from "react";
// import "./productCatalog.css";
// import { Box, Grid, Typography } from "@mui/material";
// import product3 from "../../assets/images/product3.png";
// import Card from "./card/Card";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import { useParams } from "react-router-dom";
// import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
// import { fireStore } from "../../firebase";
// import ImgPreview from "../imgPreview/ImgPreview";

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

// function CustomTabPanel(props) {
//   const { children, value, index, ...other } = props;
//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
//     </div>
//   );
// }

// function ProductCatalog() {
//   const params = useParams();
//   const { category } = params;
//   const [open, setOpen] = React.useState(false);
//   const [imgUrl, setImgUrl] = React.useState("");
//   const [value, setValue] = React.useState(
//     category ? category : "naylon-kasab"
//   );
//   const [products, setProducts] = React.useState([]);
//   const [filteredProducts, setFilteredProducts] = React.useState([]);

//   const handleChange = (event, newValue) => {
//     getFilteredProducts(newValue);
//     setValue(newValue);
//   };

//   const getFilteredProducts = (value) => {
//     if (products) {
//       const data_ = products.filter((data) => {
//         return data?.type?.toLowerCase() === value?.toLowerCase();
//       });
//       setFilteredProducts([...data_]);
//     }
//   };

//   // async function getProducts() {
//   //   // const docRef = await addDoc(collection(fireStore, "products"), {
//   //   //   evenness: "Smooth",
//   //   //   name: "Rama Zari Thread",
//   //   //   material: "Polyester",
//   //   //   twist: "1240TPM",
//   //   //   usages: "Emboidery, Knitting, Weaving",
//   //   //   type: "SType",
//   //   //   length: "6200 Meter",
//   //   //   deniar: "210",
//   //   //   img: "",
//   //   // });

//   //   const querySnapshot = await getDocs(collection(fireStore, "products"));
//   //   //   console.log(querySnapshot);
//   //   let product = [];
//   //   let product_ = [];

//   //   if (querySnapshot) {
//   //     querySnapshot.forEach((doc) => {
//   //       const data = doc.data();
//   //       product.push({ ...data, id: doc.id });
//   //       data?.type?.toLowerCase() === value?.toLowerCase() &&
//   //         product_.push(data);
//   //     });
//   //   }
//   //   setProducts([...product]);
//   //   setFilteredProducts([...product_]);
//   // }
//   // useEffect(() => {
//   //   getProducts();
//   // }, []);

//   useEffect(() => {
//     {
//       category && setValue(category);
//     }
//   }, [category]);

//   // useEffect(() => {
//   //   getFilteredProducts(value);
//   // }, [value]);

//   const categoryList = [
//     {
//       type: "naylon-kasab",
//       title: "Naylon Kasab",
//     },
//     {
//       type: "02-mx-single-momo-badla",
//       title: "02-MX Single momo Badla",
//     },
//     {
//       type: "75-2-doubling-with-momo",
//       title: "75/2 Doubling with Momo",
//     },
//     {
//       type: "75-2-doubling-without-momo",
//       title: "75/2 Doubling without Momo",
//     },
//     {
//       type: "07-chapat-jari",
//       title: "07 - Chapat Jari",
//     },
//     {
//       type: "10-banarsi-dori",
//       title: "10 Banarsi Dori",
//     },
//     {
//       type: "11-mx-badala",
//       title: "11 MX Badala",
//     },
//     {
//       type: "glitter-dori",
//       title: "Glitter Dori",
//     },
//     {
//       type: "dayed-yarn",
//       title: "Dayed Yarn",
//     },
//     {
//       type: "75-single-kasab",
//       title: "75 Single Kasab",
//     },
//     {
//       type: "embroidery-jari",
//       title: "Embroidery Jari",
//     },
//     {
//       type: "raschel-jari",
//       title: "Raschel Jari",
//     },
//     {
//       type: "m150-96-polyester-180-48-polyester",
//       title: "150/96 Polyester - 180/48 polyester",
//     },
//     {
//       type: "lacquered-coated-film",
//       title: "Lacquered Coated Film",
//     },
//     {
//       type: "metallic-yarn",
//       title: "Metallic Yarn",
//     },
//   ];

//   let filteredProducts_ = [1, 2, 3, 4, 5, 6, 7, 8];

//   async function getProducts() {
//     const querySnapshot = await getDocs(collection(fireStore, "products"));
//     //   console.log(querySnapshot);
//     let products_ = [];
//     if (querySnapshot) {
//       querySnapshot.forEach((doc) => {
//         const data = doc.data();
//         products_.push({ ...data, id: doc.id });
//       });
//     }
//     setProducts([
//       ...products_.sort(
//         (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
//       ),
//     ]);
//   }

//   useEffect(() => {
//     getProducts();
//   }, []);

//   return (
//     <Box sx={{ my: 5 }}>
//       <Box sx={{ mx: "auto" }}>
//         <Tabs
//           value={value}
//           onChange={handleChange}
//           aria-label="basic tabs example"
//           variant="scrollable"
//           scrollButtons
//           allowScrollButtonsMobile
//           // textColor="inherit"
//           // indicatorColor="inherit"
//           // sx={{ mx: "auto", textAlign: "center" }}
//         >
//           {categoryList?.map((data) => {
//             return (
//               <Tab
//                 label={data?.title}
//                 value={data?.type}
//                 {...a11yProps(data?.type)}
//               />
//             );
//           })}
//           {/* <Tab label="M Type" value="naylon-kasab" {...a11yProps("naylon-kasab")} />
//           <Tab label="S Type" value="stype" {...a11yProps("stype")} />
//           <Tab label="K Type" value="ktype" {...a11yProps("ktype")} /> */}
//         </Tabs>
//       </Box>

//       {categoryList?.map((data) => {
//         const filteredProducts = products.filter(
//           (item) => item?.type === data?.type
//         );
//         return (
//           <CustomTabPanel value={value} index={data?.type}>
//             <Grid
//               container
//               spacing={5}
//               // direction={!matchesMd ? "column-reverse" : "row"}
//               justifyContent="center"
//               alignItems="center"
//               sx={{ pb: 3 }}
//             >
//               {filteredProducts?.length > 0 ? (
//                 filteredProducts?.map((data) => {
//                   return (
//                     <Grid
//                       item
//                       lg={3}
//                       md={4}
//                       sm={6}
//                       xs={12}
//                       data-aos="fade-up"
//                       //   sx={{ pt: "10px !important" }}
//                     >
//                       <Box
//                         sx={{
//                           boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
//                           p: 1,
//                           cursor: "pointer",
//                           transition: "all .3s ease",
//                           "&:hover": {
//                             boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
//                           },
//                         }}
//                         onClick={() => {
//                           setOpen(true);
//                           setImgUrl(data?.imgUrl);
//                         }}
//                       >
//                         <img loading="lazy"
//                           src={data?.imgUrl}
//                           alt=""
//                           width={"100%"}
//                           height={"100%"}
//                           style={{ minHeight: "325px", maxHeight: "325px" }}
//                         />
//                       </Box>
//                       {/* <Card
//                         title={data?.name}
//                         type={data?.type}
//                         description={
//                           "For Weaving, Making Fancy Yarns, Ribbons and Woven Labels"
//                         }
//                         data={data}
//                       /> */}
//                     </Grid>
//                   );
//                 })
//               ) : (
//                 <Typography mt={10}>No Products available!</Typography>
//               )}
//             </Grid>
//           </CustomTabPanel>
//         );
//       })}

//       <img loading="lazy"Preview open={open} setOpen={setOpen} imgUrl={imgUrl} />
//     </Box>
//   );
// }

// export default ProductCatalog;

import React, { useEffect, useState } from "react";
import "./productCatalog.css";
import { Box, Grid, Typography } from "@mui/material";
import Card from "./card/Card";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate, useParams } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { fireStore } from "../../firebase";
import ImgPreview from "../imgPreview/ImgPreview";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function ProductCatalog() {
  const navigate = useNavigate();
  const params = useParams();
  const { category } = params;
  const [open, setOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [value, setValue] = useState(category ? category : "naylon-kasab");
  const [products, setProducts] = useState([]);

  const categoryList = [
    { type: "naylon-kasab", title: "Naylon Kasab" },
    { type: "02-mx-single-momo-badla", title: "02-MX Single momo Badla" },
    { type: "75-2-doubling-with-momo", title: "75/2 Doubling with Momo" },
    { type: "75-2-doubling-without-momo", title: "75/2 Doubling without Momo" },
    { type: "07-chapat-jari", title: "07 - Chapat Jari" },
    { type: "10-banarsi-dori", title: "10 Banarsi Dori" },
    { type: "11-mx-badala", title: "11 MX Badala" },
    { type: "glitter-dori", title: "Glitter Dori" },
    { type: "dayed-yarn", title: "Dayed Yarn" },
    { type: "75-single-kasab", title: "75 Single Kasab" },
    { type: "embroidery-jari", title: "Embroidery Jari" },
    { type: "raschel-jari", title: "Raschel Jari" },
    {
      type: "m150-96-polyester-180-48-polyester",
      title: "150/96 Polyester - 180/48 polyester",
    },
    { type: "lacquered-coated-film", title: "Lacquered Coated Film" },
    { type: "metallic-yarn", title: "Metallic Yarn" },
  ];

  const handleChange = (event, newValue) => {
    console.log("newValue", newValue);
    navigate(`/products/${newValue}`);
    setValue(newValue);
  };

  async function getProducts() {
    const querySnapshot = await getDocs(collection(fireStore, "products"));
    let products_ = [];
    if (querySnapshot) {
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        products_.push({ ...data, id: doc.id });
      });
    }
    setProducts([
      ...products_.sort(
        (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
      ),
    ]);
  }

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (category) {
      setValue(category);
    }
  }, [category]);

  return (
    <Box sx={{ display: "flex", my: 5 }}>
      {/* Vertical Tabs Container */}
      <Box
        sx={{
          width: "250px",
          borderRight: 1,
          borderColor: "divider",
          position: "sticky",
          top: 0,
          height: "calc(100vh - 100px)",
          overflowY: "auto",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs"
          sx={{
            borderRight: 1,
            borderColor: "divider",
            width: "100%",
            alignItems: "flex-start", // Align tabs to the start (left)
          }}
        >
          {categoryList?.map((data) => (
            <Tab
              key={data.type}
              label={data.title}
              value={data.type}
              {...a11yProps(data.type)}
              sx={{
                textAlign: "left", // Align text to the left
                justifyContent: "flex-start", // Align content to the start
                width: "100%",
                alignItems: "flex-start", // Align items to the start
                textTransform: "none", // Prevent uppercase transformation
                paddingLeft: 3, // Add some left padding
              }}
            />
          ))}
        </Tabs>
      </Box>

      {/* Content Container */}
      <Box sx={{ flexGrow: 1, p: 3, width: "calc(100% - 250px)" }}>
        {categoryList?.map((data) => {
          const filteredProducts = products.filter(
            (item) => item?.type === data?.type
          );

          return (
            value === data.type && (
              <Box key={data.type}>
                <Typography variant="h4" gutterBottom>
                  {data.title}
                </Typography>
                <Grid
                  container
                  spacing={3}
                  justifyContent="flex-start"
                  alignItems="stretch"
                >
                  {filteredProducts?.length > 0 ? (
                    filteredProducts?.map((product) => (
                      <Grid item key={product.id} lg={3} md={4} sm={6} xs={12}>
                        <Box
                          sx={{
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
                            p: 1,
                            cursor: "pointer",
                            height: "100%",
                            width: "100%",
                            transition: "all .3s ease",
                            "&:hover": {
                              boxShadow:
                                "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                            },
                          }}
                          onClick={() => {
                            setOpen(true);
                            setImgUrl(product?.imgUrl);
                          }}
                        >
                          <Card data={product} title={data?.title} />

                          {/* <img
                            loading="lazy"
                            src={product?.imgUrl}
                            alt={product?.name}
                            width="100%"
                            height="325px"
                            style={{
                              objectFit: "cover",
                              minHeight: "325px",
                              maxHeight: "325px",
                            }}
                          /> */}
                        </Box>
                      </Grid>
                    ))
                  ) : (
                    <Typography mt={10}>No Products available!</Typography>
                  )}
                </Grid>
              </Box>
            )
          );
        })}
      </Box>

      <img
        loading="lazy"
        Preview
        open={open}
        setOpen={setOpen}
        imgUrl={imgUrl}
      />
    </Box>
  );
}

export default ProductCatalog;
